<template>
  <div>
    <div id="top"></div>
    <search-table
      ref="multiTable"
      :stripe="false"
      :data="dentForm.dent_mtrb_list"
      :tableRowKey="'dent_mtrb_list'"
      :columns="tableProperties"
      :need-pagination="false"
      :need-fixed-height="true"
      :row-class-name="tableRowClassName"
      @row-click="setChangeRowIndex"
      @selection-change="handleSelectionChange"
      @getTableData="localSearch"
    >
      <template v-slot:subItem-logistics_no="scope">
        <el-link type="primary" @click="showDialog(scope.row.logistics_no)">查看</el-link>
      </template>
      <template v-slot:operate="scope">
        <el-form-item label-width="0">
          <el-button
            plain
            v-if="dentForm.dent_mtrb_list[scope.$index].qrcode_list"
            class="vg_mb_5"
            size="small"
            type="primary"
            @click="lookImg(scope.$index)"
            >生成材料二维码
          </el-button>
          <el-button
            plain
            :disabled="!isShow || Number(scope.row.dent_mtrb_dnum) / Number(scope.row.dent_mtrb_qpc) === 0 || scope.row.disabled"
            size="small"
            type="warning"
            @click="generateQR(scope.$index)"
            >生成箱唛二维码
          </el-button>
        </el-form-item>
      </template>
    </search-table>

    <!--    <el-table
      :data="dentForm.dent_mtrb_list"
      border
      @row-click="setChangeRowIndex"
      @selection-change="handleSelectionChange"
      ref="dentTable"
      class="vg_cursor_hander"
      :row-class-name="tableRowClassName"
      :header-cell-class-name="cellClassName"
      @header-click="headerClickHandel('dentTable', $event)"
    >
      <el-table-column type="selection" width="48" align="center" />
      <el-table-column :fixed="fixed" width="1" class="vd_hidden"></el-table-column>
      <el-table-column label="编号" width="60" align="center">
        <template v-slot="scope">
          {{ scope.$index + 1 }}
        </template>
      </el-table-column>
      <el-table-column label="订单类型" width="80" align="center" prop="dent_mtrb_type" :formatter="formatDentMtrbType" />
      <el-table-column label="材料采购号" align="center" prop="modr_no" width="150px" v-if="isMerge">
        <template v-slot="scope">
          <el-form-item label-width="0" :prop="'dent_mtrb_list.' + scope.$index + '.modr_no'">
            <el-input
              v-model="dentForm.dent_mtrb_list[scope.$index].modr_no"
              disabled
              maxlength="30"
              show-word-limit
              placeholder="暂无材料采购号"
            >
              <template slot="append">
                <el-popover placement="bottom" width="600" @show="textEnlargementShow(2, scope.$index)" trigger="click">
                  <textEnlargement
                    ref="textEnlargement"
                    :disabledFlag="true"
                    @textEnlargementChange="textEnlargementChange"
                    :textEnlargementString="textEnlargementString"
                  ></textEnlargement>
                  <span slot="reference"><el-link type="info">...</el-link></span>
                </el-popover>
              </template>
            </el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="成品采购号" align="center" prop="podr_no" v-if="isMerge" />
      <el-table-column label="材料名称" width="200px">
        <template v-slot="scope">
          <el-form-item label-width="0" :prop="'dent_mtrb_list.' + scope.$index + '.mtrb_name'">
            <el-input
              v-model="dentForm.dent_mtrb_list[scope.$index].mtrb_name"
              disabled
              maxlength="30"
              show-word-limit
              placeholder="暂无材料名称"
            >
              <template slot="append">
                <el-popover placement="bottom" width="600" @show="textEnlargementShow(0, scope.$index)" trigger="click">
                  <textEnlargement
                    ref="textEnlargement"
                    :disabledFlag="true"
                    @textEnlargementChange="textEnlargementChange"
                    :textEnlargementString="textEnlargementString"
                  ></textEnlargement>
                  <span slot="reference"><el-link type="info">...</el-link></span>
                </el-popover>
              </template>
            </el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="材料规格" width="200px">
        <template v-slot="scope">
          <el-form-item label-width="0" :prop="'dent_mtrb_list.' + scope.$index + '.mtrb_spec'">
            <el-input
              v-model="dentForm.dent_mtrb_list[scope.$index].mtrb_spec"
              disabled
              maxlength="30"
              show-word-limit
              placeholder="暂无材料规格"
            >
              <template slot="append">
                <el-popover placement="bottom" width="600" @show="textEnlargementShow(1, scope.$index)" trigger="click">
                  <textEnlargement
                    ref="textEnlargement"
                    :disabledFlag="true"
                    @textEnlargementChange="textEnlargementChange"
                    :textEnlargementString="textEnlargementString"
                  ></textEnlargement>
                  <span slot="reference"><el-link type="info">...</el-link></span>
                </el-popover>
              </template>
            </el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="客户货号" width="150px">
        <template v-slot="scope">
          <el-form-item label-width="0" :prop="'dent_mtrb_list.' + scope.$index + '.cust_artno'">
            <el-input
              v-model="dentForm.dent_mtrb_list[scope.$index].cust_artno"
              disabled
              maxlength="30"
              show-word-limit
              placeholder="暂无客户货号"
            ></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="订单数量" width="150px">
        <template v-slot="scope">
          <el-form-item label-width="0" :prop="'dent_mtrb_list.' + scope.$index + '.dent_mtrb_num'">
            <el-input
              v-if="!dentForm.dent_mtrb_list[scope.$index].dent_mtrb_znums"
              v-model="dentForm.dent_mtrb_list[scope.$index].dent_mtrb_num"
              disabled
              maxlength="30"
              show-word-limit
              placeholder="暂无总数量"
            ></el-input>
            <el-input
              v-else
              v-model="dentForm.dent_mtrb_list[scope.$index].dent_mtrb_znums"
              disabled
              maxlength="30"
              show-word-limit
              placeholder="暂无总数量"
            >
              <template slot="append">
                <el-popover placement="bottom" width="600" @show="textEnlargementShow(4, scope.$index)" trigger="click">
                  <textEnlargement
                    ref="textEnlargement"
                    :disabledFlag="true"
                    @textEnlargementChange="textEnlargementChange"
                    :textEnlargementString="textEnlargementString"
                  ></textEnlargement>
                  <span slot="reference"><el-link type="info">...</el-link></span>
                </el-popover>
              </template>
            </el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="备品数量" width="100px">
        <template v-slot="scope">
          <el-form-item label-width="0" :prop="'dent_mtrb_list.' + scope.$index + '.dent_mtrb_bnum'">
            <el-input
              v-if="!dentForm.dent_mtrb_list[scope.$index].dent_mtrb_bnums"
              v-model="dentForm.dent_mtrb_list[scope.$index].dent_mtrb_bnum"
              disabled
              maxlength="30"
              show-word-limit
              placeholder="暂无备品数量"
            ></el-input>
            <el-input
              v-else
              v-model="dentForm.dent_mtrb_list[scope.$index].dent_mtrb_bnums"
              disabled
              maxlength="30"
              show-word-limit
              placeholder="暂无备品数量"
            >
              <template slot="append">
                <el-popover placement="bottom" width="600" @show="textEnlargementShow(5, scope.$index)" trigger="click">
                  <textEnlargement
                    ref="textEnlargement"
                    :disabledFlag="true"
                    @textEnlargementChange="textEnlargementChange"
                    :textEnlargementString="textEnlargementString"
                  ></textEnlargement>
                  <span slot="reference"><el-link type="info">...</el-link></span>
                </el-popover>
              </template>
            </el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="单位">
        <template v-slot="scope">
          <el-form-item label-width="0" :prop="'dent_mtrb_list.' + scope.$index + '.dent_mtrb_unit'">
            <el-input
              v-model="dentForm.dent_mtrb_list[scope.$index].dent_mtrb_unit"
              disabled
              maxlength="30"
              show-word-limit
              placeholder="暂无单位"
            ></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="已送货数量">
        <template v-slot="scope">
          <el-form-item label-width="0" :prop="'dent_mtrb_list.' + scope.$index + '.dent_mtrb_ynum'">
            <el-input
              v-model="dentForm.dent_mtrb_list[scope.$index].dent_mtrb_ynum"
              disabled
              maxlength="30"
              show-word-limit
              placeholder="暂无已送货数量"
            ></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="本次送货数量" width="120px">
        <template slot="header">
          <span class="vg_deep_red">本次送货数量</span>
        </template>
        <template v-slot="scope">
          <el-form-item
            label-width="0"
            :prop="'dent_mtrb_list.' + scope.$index + '.dent_mtrb_dnum'"
            :rules="{ required: true, message: ' ' }"
          >
            <el-input
              :disabled="isShow || dentForm.dent_mtrb_list[scope.$index].dent_mtrb_status === 3 || scope.row.disabled"
              @change="dentMtrbDnumChange(scope.$index)"
              v-model="dentForm.dent_mtrb_list[scope.$index].dent_mtrb_dnum"
              @input="
                dentForm.dent_mtrb_list[scope.$index].dent_mtrb_dnum = helper.keepTNum(dentForm.dent_mtrb_list[scope.$index].dent_mtrb_dnum)
              "
              maxlength="10"
              show-word-limit
              placeholder="暂无本次送货数量"
            ></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="装箱数量" width="100px">
        <template slot="header">
          <span class="vg_deep_red">装箱数量</span>
        </template>
        <template v-slot="scope">
          <el-form-item
            label-width="0"
            :prop="'dent_mtrb_list.' + scope.$index + '.dent_mtrb_qpc'"
            :rules="{ required: true, message: ' ' }"
          >
            <el-input
              :disabled="isShow || dentForm.dent_mtrb_list[scope.$index].dent_mtrb_status === 3 || scope.row.disabled"
              v-model="dentForm.dent_mtrb_list[scope.$index].dent_mtrb_qpc"
              @input="
                dentForm.dent_mtrb_list[scope.$index].dent_mtrb_qpc = helper.keepTNum(dentForm.dent_mtrb_list[scope.$index].dent_mtrb_qpc)
              "
              @change="NumberOfBoxesChange(scope.$index)"
              maxlength="10"
              show-word-limit
              placeholder="暂无箱规"
            ></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="每箱重量" width="100px">
        <template slot="header">
          <span class="vg_deep_red">每箱重量</span>
        </template>
        <template v-slot="scope">
          <el-form-item
            label-width="0"
            :prop="'dent_mtrb_list.' + scope.$index + '.case_weight'"
            :rules="{ /*validator: checkCaseWeight,*/ required: false, message: '', trigger: 'blur' }"
          >
            <el-input
              :disabled="isShow || scope.row.disabled"
              v-model="dentForm.dent_mtrb_list[scope.$index].case_weight"
              @input="
                dentForm.dent_mtrb_list[scope.$index].case_weight = helper.keepTNum1(dentForm.dent_mtrb_list[scope.$index].case_weight)
              "
              @blur="
                dentForm.dent_mtrb_list[scope.$index].case_weight = helper.calcPrice(
                  dentForm.dent_mtrb_list[scope.$index].case_weight,
                  4,
                  100000000
                )
              "
              maxlength="13"
              show-word-limit
              placeholder="暂无每箱重量"
            ></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="箱数" width="120px">
        <template v-slot="scope">
          <el-form-item label-width="0" :prop="'dent_mtrb_list.' + scope.$index + '.dent_mtrb_box'">
            <el-input
              :disabled="true"
              v-model="dentForm.dent_mtrb_list[scope.$index].dent_mtrb_box"
              maxlength="10"
              show-word-limit
              placeholder="暂无箱数"
            ></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="物流单号" width="200px">
        <template v-slot="scope">
          <el-form-item label-width="0" :prop="'dent_mtrb_list.' + scope.$index + '.logistics_no'">
            <el-input
              :disabled="isShow || scope.row.disabled"
              v-model="dentForm.dent_mtrb_list[scope.$index].logistics_no"
              maxlength="50"
              show-word-limit
              placeholder="暂无物流单号"
            >
              <template slot="append"><el-link type="primary" @click="showDialog(scope.row.logistics_no)">查看</el-link></template>
            </el-input>
            &lt;!&ndash; <el-link v-else type="primary" size="mini" style="width: 100%"  @click="showDialog(scope.row.logistics_no)">查看快递信息</el-link> &ndash;&gt;
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="司机姓名" width="120">
        <template v-slot="scope">
          <el-form-item label-width="0" :prop="'dent_mtrb_list.' + scope.$index + '.driv_name'">
            <el-input
              :disabled="isShow || scope.row.disabled"
              v-model="dentForm.dent_mtrb_list[scope.$index].driv_name"
              maxlength="255"
              show-word-limit
              placeholder="暂无司机姓名"
            ></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="联系方式" width="150">
        <template v-slot="scope">
          <el-form-item label-width="0" :prop="'dent_mtrb_list.' + scope.$index + '.driv_phone'">
            <el-input
              :disabled="isShow || scope.row.disabled"
              v-model="dentForm.dent_mtrb_list[scope.$index].driv_phone"
              maxlength="255"
              show-word-limit
              placeholder="暂无联系方式"
            ></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center" width="150" v-if="userInfo.acct_id !== 1">
        <template v-slot="scope">
          <el-form-item label-width="0" :prop="'dent_mtrb_list.' + scope.$index + '.cust_abbr'">
            <el-button
              plain
              v-if="dentForm.dent_mtrb_list[scope.$index].qrcode_list"
              class="vg_mb_5"
              size="small"
              type="primary"
              @click="lookImg(scope.$index)"
              >生成材料二维码</el-button
            >
            <el-button
              plain
              :disabled="!isShow || Number(scope.row.dent_mtrb_dnum) / Number(scope.row.dent_mtrb_qpc) === 0 || scope.row.disabled"
              size="small"
              type="warning"
              @click="generateQR(scope.$index)"
              >生成箱唛二维码</el-button
            >
          </el-form-item>
        </template>
      </el-table-column>
    </el-table>-->
    <el-dialog title="箱唛信息" :visible.sync="dialogVisible" @close="dialogClose" width="40%">
      <el-row v-loading="loading">
        <el-col id="printDent" :md="24" class="vd_dis_cen1">
          <el-carousel
            ref="carousel"
            @change="carouselChange"
            height="100%"
            class="vd_div vd_font16 vg_mb_16"
            :autoplay="false"
            indicator-position="none"
          >
            <el-carousel-item style="height: 100%" v-for="(item, index) in QRform" :key="`my-node${index}`">
              <div :id="`my-node${index}`">
                <el-row class="vg_mb_8">
                  <el-col :md="12">
                    <el-row class="vg_mb_8">
                      <el-col :md="24">发货方:{{ item.supp_abbr }}</el-col>
                    </el-row>
                    <el-row class="vg_mb_8">
                      <el-col :md="24">收货方:{{ item.supp_babbr }}</el-col>
                    </el-row>
                    <el-row class="vg_mb_8">
                      <el-col :md="24">发货时间:{{ helper.toTimeDay(dentForm.dent_date / 1000) }}</el-col>
                    </el-row>
                  </el-col>
                  <el-col :md="12">
                    <div style="display: flex">
                      <div style="display: flex; margin-left: auto; text-align: center">
                        <div>
                          <img class="showImgcopy" :src="QRimgrul" alt="" />
                          <div>编号：dm{{ item.dent_mtrb_id }}({{ index + 1 }})</div>
                        </div>
                      </div>
                    </div>
                  </el-col>
                </el-row>
                <el-row class="vg_mb_16">
                  <el-col :md="24" style="text-align: center">
                    <div v-for="(name, subIndex) in item.name" :key="`subNum${index}`">
                      {{
                        void ((partsNum = (item.newNums ? item.newNums[subIndex] : item.dnum) / QRform.length),
                        (bShow =
                          (item.type !== 0 || item.dent_mtrb_type === 3) && ((item.finalBnum && item.finalBnum !== 0) || item.isBnums)))
                      }}
                      <el-row v-if="subIndex === 0" style="margin-bottom: 3px">
                        <div v-if="bShow">
                          <el-avatar class="roundAvatar">
                            {{ item.type !== 0 ? '备' : item.dent_mtrb_type === 3 ? '补' : '' }}
                          </el-avatar>
                        </div>
                        <el-col :md="4">包材-大货</el-col>
                        <el-col :md="12">材料名称</el-col>
                        <el-col :md="4">数量</el-col>
                        <el-col :md="4" v-if="bShow">含备</el-col>
                      </el-row>
                      <el-row>
                        <el-col :md="4" style="font-size: 12px">
                          {{
                            (item.modr_no instanceof Array
                              ? item.modr_no[subIndex].substring(item.modr_no[subIndex].indexOf('-') + 1).replace(/[^0-9]/gi, '')
                              : item.modr_no.substring(item.modr_no.indexOf('-') + 1).replace(/[^0-9]/gi, '')) +
                            '-' +
                            item.podr_no.replace(/[^0-9]/gi, '')
                          }}
                        </el-col>
                        <el-col :md="12" style="font-size: 12px">
                          <span>{{ name }}</span>
                        </el-col>
                        <el-col :md="4">{{ partsNum }}</el-col>
                        <el-col :md="4" v-if="item.finalBnum && item.finalBnum !== 0">
                          {{ item.finalBnum }}
                        </el-col>
                        <el-col :md="4" v-else-if="item.isBnums">
                          {{ item.isBnums[subIndex] }}
                        </el-col>
                      </el-row>
                    </div>
                    <el-row>
                      <el-row style="text-align: center">
                        <el-col :md="4"></el-col>
                        <el-col :md="12"><span class="vd_vis">暂无</span></el-col>
                        <el-col :md="4"></el-col>
                        <el-col :md="4"></el-col>
                      </el-row>
                    </el-row>
                  </el-col>
                </el-row>
                <el-row>
                  <el-col :md="24">重量:{{ item.case_weight }} kg</el-col>
                  <!--<el-col :md="24">箱号:{{item.allCartons}}-{{item.allCartons2 + index + 1}}</el-col>-->
                  <!--<el-col :md="24">运单箱号:{{item.currentCarton}}-{{item.currentCarton2 + index + 1}}</el-col>-->
                  <!--<el-col :md="24">箱号:{{item.allCartons}}-{{item.sliceIndex === 0 ? item.allCartons2 + item.sliceIndex + 1 : item.allCartons2 + index + 1}}</el-col>-->
                  <el-col :md="24"
                    >运单箱号:{{ item.currentCarton }}-{{
                      item.sliceIndex === 0 ? item.currentCarton2 + item.sliceIndex + 1 : item.currentCarton2 + index + 1
                    }}</el-col
                  >
                  <el-col :md="24">物流单号:{{ item.logistics_no }}</el-col>
                  <el-col :md="24">当天总箱号:</el-col>
                  <el-col :md="24">友情提示:外箱潮湿，破损一律拒签！！！</el-col>
                  <el-col :md="24">货物收到请及时清点数量，若有疑问请7天内与我司联系。</el-col>
                </el-row>
              </div>
            </el-carousel-item>
          </el-carousel>
        </el-col>
      </el-row>
      <el-row class="vd_dis_cen_font">
        <el-col :md="10">
          <el-input-number size="small" v-model="jumpInput" :min="1" :max="QRform.length" placeholder="请输入页码"></el-input-number>
          <el-button type="success" plain size="small" class="vg_ml_8" @click="jumpQRform">跳转</el-button>
        </el-col>
        <el-col :md="14">第{{ carouselIndex }}页 共{{ QRform.length }}页</el-col>
      </el-row>
      <el-row class="vg_pt_16">
        <el-col :md="12">
          <span>根据箱规下载!&nbsp;&nbsp;&nbsp;&nbsp;</span>
          <el-button type="primary" size="small" plain @click="batchUownload">批量下载</el-button>
          <el-button type="warning" size="small" plain @click="printDent">打印</el-button>
        </el-col>
        <el-col :md="12" class="vd_dis_cen">
          <el-button type="danger" size="small" plain @click="cancelDownload">取消</el-button>
        </el-col>
      </el-row>
    </el-dialog>
    <el-dialog title="快递物流信息" :visible.sync="dialogVisible2" width="30%">
      <el-timeline :reverse="false">
        <el-timeline-item v-for="(activity, index) in expressInfo" :key="index" :timestamp="activity.time">
          {{ activity.context }}
        </el-timeline-item>
      </el-timeline>
      <div slot="footer" class="dialog-footer"></div>
    </el-dialog>
    <el-dialog title="材料二维码" :visible.sync="dialogVisible3" @close="dialogClose" width="50%">
      <div style="display: flex; justify-content: center; align-items: center; flex-wrap: wrap">
        <div
          v-for="(item, index) in imgUrl"
          :id="`xiangmai${index}`"
          :key="index"
          style="
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            font-size: 18px;
            border: 1px solid gray;
            margin-right: 10px;
          "
        >
          <el-image style="width: 200px" :src="helper.picUrl(item.qrcd_imge_url, 'l', '', 0)" fit="fill" />
          <span>dm{{ item.dent_mtrb_id }}</span>
          <span>{{ item.mtrb_name }}</span>
          <span>数量:{{ item.dent_mtrb_num }}</span>
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible3 = false">取消</el-button>
        <el-button type="primary" @click="batchDown">批量下载</el-button>
      </div>
    </el-dialog>
    <el-dialog title="查看图片" :visible.sync="dialogVisible4" width="50%">
      <div style="display: flex; justify-content: center; align-items: center">
        <el-image style="width: 300px" :src="helper.picUrl(imgUrlCopy, 'l', '', 0)" fit="fill" />
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { post } from '@api/request';
import { dentAPI } from '@/api/modules/dent';
import { imgeAPI } from '@api/modules/imge';
import domtoimage from 'dom-to-image';
import helper from '@assets/js/helper';
import { saveAs } from 'file-saver';
import JSZip from 'jszip';
import printJS from 'print-js';
import textEnlargement from '@/views/component/textEnlargement.vue';
import { cloneDeep, debounce } from 'lodash';
import { myMixin } from '@/assets/js/mixin/tabMixin.js';
import SearchTable from '@/components/SearchTable.vue';
import { sliceArray } from '@assets/js/arrayUtils';

export default {
  name: 'DentSubTableList',
  mixins: [myMixin],
  props: {
    dentForm: {
      type: Object,
      required: true
    },
    isShow: {
      type: Boolean,
      required: true
    }
  },
  components: {
    SearchTable,
    textEnlargement
  },
  watch: {
    copyQRimgrul(newValue, oldValue) {
      if (newValue.length === this.tempIndex) {
        this.downClick();
        this.loading = false;
        this.cancelDownload();
      }
    }
  },
  created() {
    this.userInfo = this.$cookies.get('userInfo');
    this.initData();
  },
  mounted() {
    if (!this.$route.query.ids) {
      this.tableProperties = this.tableProperties.filter(x => x.columnShow !== false);
    }
  },
  data() {
    return {
      tableProperties: [
        this.$store.state.selection,
        this.$store.state.index,
        {
          label: '材料采购号',
          prop: 'modr_no',
          itemType: 'input',
          input: true,
          sortable: '',
          labelWidth: '140px',
          columnShow: !!this.$route.query.ids,
          formatter: val => (val ? val.replaceAll(',', '<br/>') : val)
        },
        {
          label: '材料名称',
          prop: 'mtrb_name',
          itemType: 'input',
          input: true,
          sortable: '',
          labelWidth: '140px',
          formatter: val => (val ? val.replaceAll(',', '<br/>') : val)
        },
        {
          label: '订单数量',
          prop: 'dent_mtrb_num',
          itemType: 'input',
          input: false,
          sortable: '',
          needOtherColumn: true,
          formatter: val => (val.dent_mtrb_znums ? val.dent_mtrb_znums.replaceAll(',', '<br/>') : val.dent_mtrb_num)
        },
        {
          label: '备品数量',
          prop: 'dent_mtrb_bnum',
          itemType: 'input',
          input: false,
          sortable: false,
          needOtherColumn: true,
          formatter: val => (val.dent_mtrb_bnums ? val.dent_mtrb_bnums.replaceAll(',', '<br/>') : val.dent_mtrb_bnum)
        },
        { label: '已送货数量', prop: 'dent_mtrb_ynum', itemType: 'input', input: false, sortable: false },
        {
          label: '本次送货数量',
          prop: 'dent_mtrb_dnum',
          itemType: 'input',
          input: false,
          sortable: false,
          subItem: {
            type: 'input',
            maxLength: 10,
            required: true,
            disabled: val => this.isShow || val.dent_mtrb_status === 3 || val.disabled || val.mergeFlag || val.sendFlag,
            input: (event, row) => (row.dent_mtrb_dnum = helper.keepTNum(row.dent_mtrb_dnum)),
            change: (event, row) => this.dentMtrbDnumChange(row.index)
          }
        },
        {
          label: '装箱数量',
          prop: 'dent_mtrb_qpc',
          itemType: 'input',
          input: false,
          sortable: '',
          subItem: {
            type: 'input',
            maxLength: 10,
            required: true,
            disabled: val => this.isShow || val.dent_mtrb_status === 3 || val.disabled || val.mergeFlag || val.sendFlag,
            input: (event, row) => (row.dent_mtrb_qpc = helper.keepTNum(row.dent_mtrb_qpc)),
            change: (event, row) => this.NumberOfBoxesChange(row.index)
          }
        },
        {
          label: '每箱重量',
          prop: 'case_weight',
          itemType: 'input',
          input: false,
          sortable: '',
          subItem: {
            type: 'input',
            maxLength: 13,
            required: true,
            disabled: val => this.isShow || val.disabled || val.sendFlag,
            input: (event, row) => (row.case_weight = helper.keepTNum1(row.case_weight)),
            change: (event, row) => (row.changeFlag = true),
            blur: (event, row) => (row.case_weight = helper.calcPrice(row.case_weight, 4, 100000000))
          }
        },
        { label: '箱数', prop: 'dent_mtrb_box', itemType: 'input', input: false, sortable: true, labelWidth: '80px' },
        {
          label: '成品采购号',
          prop: 'podr_no',
          itemType: 'input',
          input: true,
          sortable: '',
          columnShow: !!this.$route.query.ids,
          formatter: val => (val ? val.replaceAll(',', '<br/>') : val)
        },
        { label: '材料规格', prop: 'mtrb_spec', itemType: 'input', input: false, sortable: false, overflowTooltip: true },
        { label: '客户货号', prop: 'cust_artno', itemType: 'input', input: false, sortable: false },
        { label: '单位', prop: 'dent_mtrb_unit', itemType: 'input', input: false, sortable: false, labelWidth: '80px' },
        {
          label: '物流单号',
          prop: 'logistics_no',
          itemType: 'input',
          labelWidth: '180px',
          input: false,
          sortable: false,
          subItem: {
            type: 'input',
            maxLength: 50,
            disabled: val => this.isShow || val.disabled || val.sendFlag,
            change: (event, row) => (row.changeFlag = true)
          }
        },
        {
          label: '司机姓名',
          prop: 'driv_name',
          itemType: 'input',
          input: false,
          sortable: false,
          subItem: {
            type: 'input',
            maxLength: 255,
            disabled: val => this.isShow || val.disabled || val.sendFlag,
            change: (event, row) => (row.changeFlag = true)
          }
        },
        {
          label: '联系方式',
          prop: 'driv_phone',
          itemType: 'input',
          input: false,
          sortable: false,
          subItem: {
            type: 'input',
            maxLength: 255,
            disabled: val => this.isShow || val.disabled || val.sendFlag,
            change: (event, row) => (row.changeFlag = true)
          }
        },
        {
          label: '订单类型',
          prop: 'dent_mtrb_type',
          itemType: 'input',
          input: false,
          sortable: false,
          formatter: val => this.dentMtrbTypeList.find(({ value }) => value === val).label
        },
        {
          label: '操作',
          prop: 'operate',
          itemType: 'input',
          input: false,
          sortable: false,
          labelWidth: '150px'
        }
      ],
      /*			checkCaseWeight: (rule, value, callback) => {
				if (value <= 0) {
					this.showNotic();
					return callback(new Error());
				}
				return callback();
			},*/
      multiSelection: [],
      textEnlargementNum: 0,
      textEnlargementFlag: 0,
      textEnlargementString: {
        str: ''
      },
      dataBody: {
        file: /\.(png|jpg|gif|jpeg)$/,
        cut: true,
        keep: false,
        x: 400,
        y: 400
      },
      uploadUrl: helper.modePrepend(imgeAPI.uploadImage),
      dialogVisible: false,
      dialogVisible2: false,
      dialogVisible3: false,
      dialogVisible4: false,
      QRimgrul: '',
      copyQRimgrul: [],
      QRform: [],
      QRindex: 0,
      loading: false,
      imgUrl: [],
      dentMtrbTypeList: [
        { label: '普通', value: 0 },
        { label: '含备品', value: 1 },
        { label: '备品', value: 2 },
        { label: '补货', value: 3 }
      ],
      isMerge: this.$route.query.ids,
      expressInfo: [],
      imgUrlCopy: '',
      tempIndex: 0,
      userInfo: {},
      carouselIndex: 1,
      jumpInput: 1,
      allCartons: 0,
      allCartons2: 0,
      currentCarton: 0,
      currentCarton2: 0,
      getIndex: '',
      changeIndex: null
    };
  },
  methods: {
    setChangeRowIndex(row, column, event) {
      this.changeIndex = row.index;
    },
    initData() {},
    tableRowClassName({ row, rowIndex }) {
      if ((row.splitFlag || row.mergeFlag || row.changeFlag) && !row.sendFlag) {
        return 'changeColor';
      }
    },
    /*    selectedstyle({ row, rowIndex }) {
      if (this.getIndex === rowIndex) {
        return {
          'background-color': '#e1e1e1'
        };
      }
    },*/
    // 批量下载
    downClick(dataUrl) {
      const zip = new JSZip(); // 实例化zip
      const img = zip.folder('qrCode'); // zip包内的文件夹名字
      this.copyQRimgrul.forEach((item, index) => {
        // listOfData是含有图片的数据数组
        const basePic = item.replace(/^data:image\/(png|jpg);base64,/, ''); // 生成base64图片数据
        img.file(`二维码${index + 1}.png`, basePic, { base64: true }); // 将图片文件加入到zip包内
      });
      zip
        .generateAsync({ type: 'blob' }) // zip下载
        .then(function (content) {
          saveAs(content, '二维码.zip'); // zip下载后的名字
        });
    },
    // 批量下载
    batchUownload() {
      this.loading = true;
      let ind =
        Number(this.dentForm.dent_mtrb_list[this.QRindex].dent_mtrb_dnum) /
        Number(this.dentForm.dent_mtrb_list[this.QRindex].dent_mtrb_qpc);
      this.tempIndex = ind;
      for (let i = 0; i < ind; i++) {
        let node = document.getElementById('my-node' + i);
        domtoimage
          .toPng(node)
          .then(dataUrl => {
            this.copyQRimgrul.push(dataUrl);
          })
          .catch(function (error) {
            console.error('oops, something went wrong!', error);
          });
      }
    },
    // 取消下载
    cancelDownload() {
      this.dialogVisible = false;
      this.QRform = [];
    },
    // 更换走马灯
    carouselChange(e) {
      this.carouselIndex = Number(e) + 1;
    },
    // 跳转页码
    jumpQRform() {
      this.$refs.carousel.setActiveItem(this.jumpInput - 1);
    },
    printDent() {
      setTimeout(() => {
        printJS({
          printable: `printDent`,
          type: 'html',
          header: '',
          targetStyles: ['*']
        });
      }, 500);
    },
    // 生成二维码
    generateQR(val) {
      if (Number(this.dentForm.dent_mtrb_list[val].case_weight) === 0) {
        this.$message.error('请完善必填信息!');
        return;
      }
      let { dent_mtrb_dnum, dent_mtrb_qpc, dent_mtrb_num, dent_mtrb_ynum } = this.dentForm.dent_mtrb_list[val];
      if (dent_mtrb_ynum !== dent_mtrb_num) {
        if (Number(dent_mtrb_dnum) % Number(dent_mtrb_qpc) !== 0 || Number(dent_mtrb_dnum) === 0)
          return this.$message.warning('本次送货数量除以箱规必须为整数');
      }
      this.QRindex = val;
      this.QRcode(this.dentForm.dent_mtrb_list[val].dent_mtrb_id, val);
    },
    // 关闭弹框
    dialogClose() {
      this.QRform = [];
      this.$emit('buttonRefresh');
    },
    // 获取二维码
    QRcode(val, val1) {
      // 初始化计算箱号及运单箱号
      this.allCartons = 0;
      this.allCartons2 = 0;
      this.currentCarton = 0;
      this.currentCarton2 = 0;
      let dentMtrbListElement = this.dentForm.dent_mtrb_list[val1];
      let unfilteredIndex = this.dentForm.dent_mtrb_list.slice(-1);
      // 进行计算
      this.allCartons = this.dentForm.dent_mtrb_list.reduce((sum, { dent_mtrb_box }) => sum + dent_mtrb_box, 0);
      this.allCartons2 = this.dentForm.dent_mtrb_list.reduce(
        (sum, item) =>
          item.index < unfilteredIndex[0].index && item.index < dentMtrbListElement.index ? sum + item.dent_mtrb_box : sum + 0,
        0
      );
      let filtered = this.dentForm.dent_mtrb_list.filter(({ logistics_no }) => logistics_no === dentMtrbListElement.logistics_no);
      let filteredIndex = filtered.slice(-1);
      this.currentCarton = filtered.reduce((sum, { dent_mtrb_box }) => sum + dent_mtrb_box, 0);
      this.currentCarton2 = filtered.reduce(
        (sum, item) => (item.index < filteredIndex[0].index && item.index < dentMtrbListElement.index ? sum + item.dent_mtrb_box : sum + 0),
        0
      );
      // 请求二维码
      post(dentAPI.createShipMarkQrcode, { dent_mtrb_id: val }).then(({ data }) => {
        if (data.code === 0) {
          this.QRimgrul = helper.picUrl(data.data.qrcd_imge_url, 'l', null, 0);
          this.dialogVisible = true;
        }
      });
      // 生成箱唛信息总页数
      let generateInfo = Number(dentMtrbListElement.dent_mtrb_dnum) / Number(dentMtrbListElement.dent_mtrb_qpc);
      let type = 0;
      let arr = new Array(generateInfo);
      let pageNum = dentMtrbListElement.dent_mtrb_dnum / generateInfo;
      let bnum = dentMtrbListElement.dent_mtrb_bnum;
      for (let i = 0; i < generateInfo; i++) {
        let number = bnum - pageNum;
        if (number > 0) {
          arr[i] = number;
          bnum -= number;
        } else {
          arr[i] = bnum;
          bnum = 0;
        }
      }
      arr = arr.sort((a, b) => a - b);
      for (let i = 0; i < generateInfo; i++) {
        let item = {};
        item.id = 'my-node' + i;
        item.dent_mtrb_id = dentMtrbListElement.dent_mtrb_id;
        // 发货方
        item.supp_abbr = this.dentForm.supp_abbr;
        // 收货方
        item.supp_babbr = this.dentForm.supp_babbr;
        // 材料采购号
        item.modr_no =
          dentMtrbListElement.modr_no?.indexOf(',') !== -1 ? dentMtrbListElement.modr_no.split(',') : dentMtrbListElement.modr_no;
        // 成品采购号
        item.podr_no = this.dentForm.podr_no;
        // 重量
        item.case_weight = dentMtrbListElement.case_weight;
        // 类型
        item.dent_mtrb_type = dentMtrbListElement.dent_mtrb_type;
        // 物流单号
        item.logistics_no = dentMtrbListElement.logistics_no;
        // 箱号
        item.allCartons = this.allCartons;
        // 箱号开始数
        item.allCartons2 = this.allCartons2;
        // 运单箱号
        item.currentCarton = this.currentCarton;
        // 运单箱号开始数
        item.currentCarton2 = this.currentCarton2;

        item.num = dentMtrbListElement.dent_mtrb_num;
        item.znums = null;
        if (dentMtrbListElement.dent_mtrb_znums) {
          item.znums = 0;
          dentMtrbListElement.dent_mtrb_znums.split(',').forEach(znum => (item.znums += Number(znum)));
        }
        item.name = dentMtrbListElement.mtrb_name?.split(',');
        if (item.name instanceof Array && item.name.length > 5) {
          item.nums = dentMtrbListElement.dent_mtrb_nums?.split(',');
          item.bnums = dentMtrbListElement.dent_mtrb_bnums?.split(',');
          item.ynums = dentMtrbListElement.dent_mtrb_ynums?.split(',');
          item.znums = dentMtrbListElement.dent_mtrb_znums?.split(',');
          let foreachIndex = 0;
          // 分割数组 分批添加
          sliceArray(item.name, 5).forEach((arr, index) => {
            let newItem = cloneDeep(item);
            newItem.name = arr;
            newItem.isBnums = [];
            newItem.newNums = [];
            for (let j = 0; j < arr.length; j++) {
              // 判断是否大于
              let value =
                (newItem.nums?.[foreachIndex] ?? 0) + (newItem.ynums?.[foreachIndex] ?? 0) > (newItem.znums?.[foreachIndex] ?? 0)
                  ? newItem.bnums?.[foreachIndex] ?? 0
                  : 0;
              newItem.isBnums.push(value);
              newItem.newNums.push(Number(newItem.nums?.[foreachIndex] ?? 0) + Number(newItem.bnums?.[foreachIndex] ?? 0));
              type += value;
              foreachIndex++;
            }
            newItem.sliceIndex = index;
            if (index !== 0) {
              newItem.sliceIndex = 0;
            }
            newItem.type = type;
            this.QRform.push(newItem);
          });
        } else if (item.name instanceof Array && item.name.length <= 5 && item.name.length > 1) {
          item.nums = dentMtrbListElement.dent_mtrb_nums?.split(',');
          item.bnums = dentMtrbListElement.dent_mtrb_bnums?.split(',');
          item.ynums = dentMtrbListElement.dent_mtrb_ynums?.split(',');
          item.znums = dentMtrbListElement.dent_mtrb_znums?.split(',');
          item.isBnums = [];
          item.newNums = [];
          for (let j = 0; j < item.name.length; j++) {
            let value = (item.nums?.[j] ?? 0) + (item.ynums?.[j] ?? 0) > (item.znums?.[j] ?? 0) ? item.bnums?.[j] ?? 0 : 0;
            item.isBnums.push(value);
            item.newNums.push(Number(item.nums?.[j] ?? 0) + Number(item.bnums?.[j] ?? 0));
            type += value;
          }
          item.type = type;
          this.QRform.push(item);
        } else {
          item.newNums = null;
          item.isBnums = null;
          item.num = dentMtrbListElement.dent_mtrb_num;
          item.dnum = dentMtrbListElement.dent_mtrb_dnum;
          item.ynum = dentMtrbListElement.dent_mtrb_ynum;
          item.bnum = dentMtrbListElement.dent_mtrb_bnum;
          item.isBnum = item.dnum + item.ynum > item.num ? item.bnum : 0;
          item.type = item.isBnum;
          item.finalBnum = arr[i];
          this.QRform.push(item);
        }
      }
    },

    // 成功上传
    handleAvatarSuccess(res, file, fileList, val) {
      if (res.code === 0) {
        const isLt5M = file.size / 1024 / 1024 < 1;
        if (!isLt5M) {
          return this.$message.error('文件大小不得超过 1MB!');
        }
        let urlEng = JSON.parse(JSON.stringify(res.data.imge_url));
        this.dentForm.dent_mtrb_list[val].imge_id = res.data.imge_id;
        this.dentForm.dent_mtrb_list[val].imge_url = urlEng;
      }
    },
    // 上传头像前
    beforeAvatarUpload(val) {},
    // 更改本次送货数量
    dentMtrbDnumChange(val) {
      if (
        Number(this.dentForm.dent_mtrb_list[val].dent_mtrb_dnum) + Number(this.dentForm.dent_mtrb_list[val].dent_mtrb_ynum) >
        Number(this.dentForm.dent_mtrb_list[val].dent_mtrb_num) + Number(this.dentForm.dent_mtrb_list[val].dent_mtrb_bnum)
      ) {
        this.$message.warning('本次收货数量加上已收货数量不能大于总数量');
        this.dentForm.dent_mtrb_list[val].dent_mtrb_dnum = '';
      }
      this.NumberOfBoxesChange(val);
    },
    // 计算箱数
    NumberOfBoxesChange(val) {
      if (Number(this.dentForm.dent_mtrb_list[val].dent_mtrb_dnum) % Number(this.dentForm.dent_mtrb_list[val].dent_mtrb_qpc) !== 0) {
        this.$message.warning('箱数不能取小数，已向上取整');
        this.dentForm.dent_mtrb_list[val].dent_mtrb_box = Math.ceil(
          Number(this.dentForm.dent_mtrb_list[val].dent_mtrb_dnum) / Number(this.dentForm.dent_mtrb_list[val].dent_mtrb_qpc)
        );
      } else {
        this.dentForm.dent_mtrb_list[val].dent_mtrb_box =
          Number(this.dentForm.dent_mtrb_list[val].dent_mtrb_dnum) / Number(this.dentForm.dent_mtrb_list[val].dent_mtrb_qpc);
      }
      this.$refs.multiTable.tableData[val].changeFlag = true;
      // this.dentForm.dent_mtrb_list[val].changeFlag = true;
    },
    // 多选
    handleSelectionChange(val) {
      this.multiSelection = [];
      this.multiSelection = val;
      this.$emit('handleSelectionChange', this.multiSelection);
    },
    // 框显示效果
    textEnlargementShow(val, val1) {
      this.textEnlargementFlag = val;
      this.textEnlargementNum = val1;
      switch (val) {
        case 0:
          this.textEnlargementString.str = this.dentForm.dent_mtrb_list[val1].mtrb_name;
          break;
        case 1:
          this.textEnlargementString.str = this.dentForm.dent_mtrb_list[val1].mtrb_spec;
          break;
        case 2:
          this.textEnlargementString.str = this.dentForm.dent_mtrb_list[val1].modr_no;
          break;
        case 3:
          this.textEnlargementString.str = this.dentForm.dent_mtrb_list[val1].dent_mtrb_nums;
          break;
        case 4:
          this.textEnlargementString.str = this.dentForm.dent_mtrb_list[val1].dent_mtrb_znums;
          break;
        case 5:
          this.textEnlargementString.str = this.dentForm.dent_mtrb_list[val1].dent_mtrb_bnums;
          break;
      }
    },
    // 更改值
    textEnlargementChange(val) {
      if (this.textEnlargementFlag === 0) {
        this.dentForm.dent_mtrb_list[this.textEnlargementNum].mtrb_name = val;
      } else if (this.textEnlargementFlag === 1) {
        this.dentForm.dent_mtrb_list[this.textEnlargementNum].mtrb_spec = val;
      } else if (this.textEnlargementFlag === 2) {
        this.dentForm.dent_mtrb_list[this.textEnlargementNum].modr_no = val;
      } else if (this.textEnlargementFlag === 3) {
        this.dentForm.dent_mtrb_list[this.textEnlargementNum].dent_mtrb_nums = val;
      }
    },
    showDialog(val) {
      if (!val) return this.$message.warning('请填写快递单号!');
      post('/api/kuaidi100/realTimeQueryTrack', { num: val }).then(({ data }) => {
        if (data.status === '200') {
          this.expressInfo = data.data;
          this.dialogVisible2 = true;
        } else {
          this.$message.warning('请检查快递单号!');
        }
      });
    },
    // 查看图片
    lookImg(val) {
      let cloneData = cloneDeep(this.dentForm.dent_mtrb_list[val].qrcode_list);
      if (!cloneData[0].qrcd_imge_url) {
        this.$message.warning('请先生成箱唛二维码!');
        return;
      }
      this.imgUrl = cloneData;
      this.dialogVisible3 = true;
    },
    // 查看图片
    lookImgCopy(val) {
      this.imgUrlCopy = this.dentForm.dent_mtrb_list[val].imge_url;
      this.dialogVisible4 = true;
    },
    showNotic: debounce(function (callback) {
      this.$message.warning('每箱重量不能为0!');
    }, 500),
    clearSelection() {
      this.$refs.dentTable.clearSelection();
    },
    formatDentMtrbType({ dent_mtrb_type }) {
      return this.dentMtrbTypeList.find(({ value }) => value === dent_mtrb_type).label;
    },
    batchDown() {
      this.loading = true;
      this.imgUrl.forEach((item, index) => {
        let node = document.getElementById('xiangmai' + index);
        domtoimage.toPng(node, { cacheBust: true }).then(dataUrl => {
          this.copyQRimgrul.push(dataUrl);
        });
      });
      setTimeout(() => {
        this.loading = false;
        const zip = new JSZip(); // 实例化zip
        const img = zip.folder('qrCode'); // zip包内的文件夹名字
        this.copyQRimgrul.forEach((item, index) => {
          // listOfData是含有图片的数据数组
          const basePic = item.replace(/^data:image\/(png|jpg);base64,/, ''); // 生成base64图片数据
          img.file(`${this.dentForm.modr_no}-${this.imgUrl[index].mtrb_name}.png`, basePic, { base64: true }); // 将图片文件加入到zip包内
        });
        zip
          .generateAsync({ type: 'blob' }) // zip下载
          .then(function (content) {
            saveAs(content, '二维码.zip'); // zip下载后的名字
          });
        this.cancelDownload();
      }, 2000);
    },
    localSearch(val) {
      if (val) {
        let prop = this.tableProperties[val].prop;
        console.log(val);
        this.dentForm.dent_mtrb_list = this.dentForm.dent_mtrb_list.filter(x => x[prop].includes(this.$refs.multiTable.searchForm[prop]));
      } else {
        let dentSubTableBackup = this.$store.state.dentSubTableBackup;
        this.dentForm.dent_mtrb_list.forEach(item => {
          dentSubTableBackup[dentSubTableBackup.findIndex(({ key }) => key === item.key)] = item;
        });
        this.$store.commit('setDentSubTableBackup', dentSubTableBackup);
        this.dentForm.dent_mtrb_list = dentSubTableBackup;
      }
    }
  }
};
</script>

<style scoped lang="scss">
.vd_total {
  font-size: 24px;
  text-align: right;
  width: 100%;
  padding-right: 30px;
  box-sizing: border-box;
}

.vd_color {
  color: #c70e0e;
}
.upload-demo ::v-deep .el-upload--text {
  width: auto;
  height: auto;
  text-align: left;
  border: none;
  border-radius: 0;
}
.el-upload__tip {
  margin-bottom: 16px;
}
::v-deep .vd_div {
  width: 500px;
  height: 520px;
  padding: 10px;
  background-color: #f0f0f0;
  box-sizing: border-box;
}
.vd_dis_cen {
  display: flex;
  justify-content: center;
}
.vd_dis_cen1 {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.vd_font30 {
  font-size: 30px;
  font-weight: 900;
}
.vd_font16 {
  font-size: 16px;
}
.vd_vis {
  visibility: hidden;
}
.vd_center {
  display: flex;
  justify-content: center;
}
.vd_padd30 {
  padding: 30px;
}
.vd_dis_cen_font {
  // display: flex;
  // justify-content: center;
  font-weight: 600;
  font-size: 20px;
}
.showImgcopy {
  width: 100px;
  height: 100px;
}
.roundAvatar {
  font-size: 25px !important;
  font-weight: 500;
  color: black;
  background-color: #f0f0f0;
  border: 2px solid black;
  line-height: 35px !important;
  position: absolute;
  top: -105px;
  right: 140px;
}
</style>
<style></style>
