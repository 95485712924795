/**
 * 数组工具类
 * @author HuangJun
 */

import { BigNumber } from 'bignumber.js';

/**
 * @description 根据给定的尺寸分割数组
 * @param {array} array 数组
 * @param {number} size 尺寸
 * @return [Array*] 若干数组
 */
export const sliceArray = (array, size) => {
  let result = [];
  for (let x = 0; x < Math.ceil(array.length / size); x++) {
    let start = x * size;
    let end = start + size;
    result.push(array.slice(start, end));
  }
  return result;
};
/**
 * @description 根据给定的尺寸分割数组
 * @param {array} array 数组
 * @param {number} size 尺寸
 * @return [Array*] 若干数组
 */
export const uniformArray = (array, size) => {
  let result = [];
  for (let x = 0; x < Math.ceil(array.length / size); x++) {
    let start = x * size;
    let end = start + size;
    result.push(array.slice(start, end));
  }
  return result;
};

/**
 * @description 获取数组中的id
 * @param {Array} array array
 * @param {string} column column
 * @return {*[]}
 */
export const getArrayIds = (array, column) => Array.from(array || [], item => item[column]);

/**
 * @description 删除表格行并加入标识
 * @param {string} rowId 行id(字段id)
 * @param {Array} array 表格数据
 * @param {Map} map map对象 用于存储有id的数据 并加入flag标识
 * @param {Array} selections 选中的表格数据
 * @return {Object} 表格数据, 已删除数据
 */
export const deleteTableRow = (rowId, array, map, selections) => {
  selections.forEach(item => {
    // 判断是否有对应id数据
    if (item[rowId] === 0 || item[rowId]) {
      // 增加标识
      // Vue.set(array[item.index], 'destroy_flag', 1);
      array[item.index].destroy_flag = 1;
      // map存储
      map.set(item.index, array[item.index]);
    } else {
      // 删除选中的无id数据 直接删除
      delete array[item.index];
    }
  });
  array = array.filter(x => x !== undefined && x.destroy_flag !== 1);
  return { tableData: array, map: map };
};

/**
 * @description 对象数组求和(保留4位小数)
 * @param {Array} array reduce array
 * @param {String} column reduce column
 * @param {Number} fixed keep decimal default 4
 * @param fixed2
 * @param string
 */
export const objectArrayReduce = (array, column, fixed = 4, fixed2 = null, string = false) => {
  let initValue = new BigNumber(0);
  let arr = array?.reduce((temp, item) => {
    return temp.plus(item[column] || 0);
  }, initValue);
  if (string) return arr.toString(fixed);
  return arr.toFixed(fixed, fixed2);
};
export const objectArrayDeRepeat = array => [...new Set(array.map(JSON.stringify))].map(m => JSON.parse(m));
export const arrayDeRepeat = array => [...new Set(array)];
