<template>
  <div class="vg_wrapper" v-loading="loadingFlag">
    <div class="vd_edhea" style="display: flex">
      <editHeader
        :isShow="isShow"
        :btn="btn"
        :strForm="dentForm"
        @openEdit="openEdit"
        @closeEdit="closeEdit"
        @submit="submit('dentForm')"
        style="margin-bottom: 16px !important; padding-bottom: 0 !important"
      ></editHeader>
      <div class="vd_export" v-if="isShow">
        <div>
          <el-button type="primary" size="small" @click="buttonRefresh()">刷新</el-button>
          <el-button
            type="success"
            plain
            size="small"
            @click="sendOutFactory"
            v-if="dentForm.dent_status !== 2 && dentForm.dent_status !== 3 && userInfo.acct_id !== 1"
            >发送至加工厂</el-button
          >
          <!-- <el-button type="primary" plain size="small" @click="showPrintDialog">打印预览1</el-button> -->
          <el-button type="primary" plain size="small" @click="showPrintDialog1">生成发货明细</el-button>
          <!--<el-button type="warning" plain size="small" @click="recoveryUpper">恢复上一步</el-button>-->
          <el-button plain size="small" @click="recoveryPrimary">恢复原数据</el-button>
        </div>
      </div>
      <div style="margin-left: auto">
        <el-button type="text" style="font-size: 20px" @click="showDentDetailDialog">
          <span style=""></span>本单发货<span style="color: #0b5093">{{ dentForm.grant_num }}</span
          >次
        </el-button>
      </div>
      <div class="vd_export1" v-if="!isShow"></div>
    </div>
    <el-form ref="dentForm" :model="dentForm" label-width="120px" size="mini">
      <el-row class="vg_mb_5 vg_mb_8 vg_pl_16c">
        <span>送货编号：{{ dentForm.dent_no }}</span>
      </el-row>
      <el-row class="vg_mb_5">
        <el-col :md="8">
          <el-form-item label="收货方" prop="supp_babbr">
            <el-select
              filterable
              v-model="dentForm.supp_babbr"
              placeholder="请选择供应商简称"
              @change="val => (dentForm.dent_addr = suppList.find(x => x.supp_abbr === val).supp_addr)"
            >
              <el-option v-for="item in suppList" :key="item.supp_id" :label="item.supp_abbr" :value="item.supp_abbr"> </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="发货方" prop="supp_abbr">
            <el-input disabled v-model="dentForm.supp_abbr" maxlength="30" show-word-limit placeholder="请填写发货方"> </el-input>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="发货日期" prop="dent_date">
            <el-date-picker disabled v-model="dentForm.dent_date" type="date" placeholder="选择发货日期"> </el-date-picker>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row class="vg_mb_5">
        <el-col :md="8" v-if="!showFlag">
          <el-form-item label="材料采购号" prop="modr_no">
            <el-input disabled v-model="dentForm.modr_no" maxlength="30" show-word-limit placeholder="请填写材料采购号"> </el-input>
          </el-form-item>
        </el-col>
        <el-col :md="8" v-if="!showFlag">
          <el-form-item label="成品采购号" prop="podr_no">
            <el-input disabled v-model="dentForm.podr_no" maxlength="30" show-word-limit placeholder="请填写成品采购号"> </el-input>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="要求交货日期:" prop="deli_date">
            <el-date-picker disabled v-model="dentForm.deli_date" type="date" placeholder="选择要求交货日期"> </el-date-picker>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row class="vg_mb_5">
        <el-col :md="23">
          <el-form-item label="收货信息" prop="dent_addr">
            <el-input
              v-model="dentForm.dent_addr"
              :disabled="isShow"
              maxlength="255"
              type="textarea"
              :rows="1"
              show-word-limit
              placeholder="请填写收货信息"
            >
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :md="1">
          <el-link type="primary" class="vg_cursor vd_center" @click="copySty">复制</el-link>
        </el-col>
      </el-row>
      <el-row class="vg_mb_5">
        <el-col :md="23">
          <el-form-item label="订单备注" prop="dent_remark">
            <el-input
              v-model="dentForm.dent_remark"
              :disabled="isShow"
              maxlength="255"
              type="textarea"
              :rows="6"
              show-word-limit
              placeholder="请填写订单备注"
            >
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <div class="vd_dash"></div>
      <el-row class="vg_mb_5">
        <el-col>
          <!-- v-if="userInfo.acct_id !== 1" -->
          <div class="vg_mb_8" v-if="userInfo.acct_id !== 1">
            <el-button type="primary" plain size="small" :disabled="isShow" @click="splitClick">拆分</el-button>
            <el-button type="warning" plain size="small" :disabled="isShow" @click="mergeClick">合并</el-button>
            <el-button type="success" plain size="small" :disabled="isShow" @click="batchFill">批量填充</el-button>
            <el-button type="danger" plain size="small" :disabled="isShow" @click="revoke">撤销</el-button>
          </div>
        </el-col>
      </el-row>
      <el-row>
        <el-col>
          <DentSubtableList
            ref="DentSubtableList"
            :dentForm="dentForm"
            :isShow="isShow"
            @buttonRefresh="buttonRefresh"
            @handleSelectionChange="handleSelectionChange"
          />
        </el-col>
      </el-row>
      <el-dialog tabindex="1" @keyup.native.enter="confirmSplit" center title="请输入拆分数量" :visible.sync="splitFlag" width="30%">
        <div style="text-align: center">
          <el-input-number v-model="splitNum" :min="1" placeholder="请输入拆分数量"></el-input-number>
        </div>
        <el-button slot="footer" type="primary" size="small" @click="confirmSplit">确认并拆分</el-button>
      </el-dialog>
      <el-dialog
        class="printDialog"
        title="打印预览"
        :visible.sync="printReviewVisible"
        @close="printFlag = true"
        width="842px"
        :show-close="false"
      >
        <div id="printDent" v-if="printReviewVisible">
          <div v-for="(item, index) in dialogForm" :key="index" class="vd_print_d">
            <el-row style="width: 21cm">
              <el-col :span="24" class="vg_mb_16 vd_print_c">
                <h1>供应商简称-发货明细</h1>
              </el-col>
              <el-col :span="24" class="vg_mb_16 vd_font18" v-if="dialogForm.length > 1">
                <el-col :span="12"> 材料采购号: {{ item.dent_mtrb_list[0].modr_no }} </el-col>
                <el-col :span="12"> 发货日期: {{ helper.toStringDay(item.dent_date / 1000) }} </el-col>
              </el-col>
              <el-col :span="24" class="vg_mb_16 vd_font18" v-else>
                <el-col :span="12"> 材料采购号: {{ item.modr_no }} </el-col>
                <el-col :span="12"> 发货日期: {{ helper.toStringDay(item.dent_date / 1000) }} </el-col>
              </el-col>
              <el-col :span="24" class="vg_mb_16 vd_font18" v-if="dialogForm.length > 1">
                成品采购号: {{ item.dent_mtrb_list[0].podr_no }}
              </el-col>
              <el-col :span="24" class="vg_mb_16 vd_font18" v-else> 成品采购号: {{ item.podr_no }} </el-col>
              <el-col :span="24" class="vg_mb_16 vd_font18">
                <el-col :span="12"> 收货方: {{ item.supp_babbr }} </el-col>
                <el-col :span="12"> 送货方: {{ item.supp_abbr }} </el-col>
              </el-col>
              <el-table class="vg_mb_16" :data="item.dent_mtrb_list" border style="width: 100%">
                <el-table-column type="index" align="center" label="序号" width="50"> </el-table-column>
                <el-table-column prop="mtrb_name" align="center" label="材料名称" width="130"> </el-table-column>
                <el-table-column prop="dent_mtrb_num" align="center" label="订单数量" width="70"> </el-table-column>
                <el-table-column prop="dent_mtrb_bnum" align="center" label="备品数量"> </el-table-column>
                <el-table-column prop="dent_mtrb_dnum" align="center" min-width="60" label="本次送货数量"> </el-table-column>
                <el-table-column label="备注" min-width="80" align="center">
                  <template v-slot="scope">
                    <el-input v-if="printFlag" type="textarea" :rows="2" placeholder="请输入内容" v-model="scope.row.orderRemarks">
                    </el-input>
                    <div v-else>{{ scope.row.orderRemarks }}</div>
                  </template>
                </el-table-column>
                <el-table-column min-width="80" align="center">
                  <template #header>
                    <p>物流单号</p>
                    <p>司机联系方式</p>
                  </template>
                  <template v-slot="scope">
                    <p>{{ scope.row.logistics_no }}</p>
                    <p>{{ scope.row.driv_name }}</p>
                    <p>{{ scope.row.driv_phone }}</p>
                  </template>
                </el-table-column>
              </el-table>
              <el-col :span="24">友情提示:</el-col>
              <el-col :span="24">外箱潮湿,破损一律拒签!!!</el-col>
              <el-col :span="24">货物收到请及时清点数量,若有疑问请及时与我司联系.</el-col>
            </el-row>
          </div>
        </div>
        <el-backtop target=".printDialog" :bottom="100" :visibility-height="1" />
        <div slot="title" style="display: flex; justify-content: flex-end">
          <el-button size="small" @click="printReviewVisible = false">取消</el-button>
          <el-button type="primary" size="small" @click="printDent">打印</el-button>
        </div>
      </el-dialog>
      <el-dialog
        class="printDialog"
        title="打印预览"
        :visible.sync="printReviewVisible1"
        @close="printFlag = true"
        width="1200px"
        :show-close="false"
      >
        <div id="printDent" v-if="printReviewVisible1">
          <div v-for="(item, index) in dialogForm" :key="index" class="vd_print_d">
            <el-row>
              <el-row class="vg_mb_16 vd_print_c">
                <h1>供应商简称-发货明细</h1>
              </el-row>
              <el-row class="vg_mb_16 vd_font18">
                <el-col :md="8"> 发货方: {{ item.supp_abbr }} </el-col>
                <el-col :md="8"> 收货方: {{ item.supp_babbr }} </el-col>
                <el-col :md="8"> 发货日期: {{ helper.toStringDay(item.dent_date / 1000) }} </el-col>
              </el-row>
              <el-table class="vg_mb_16" :data="item.dent_mtrb_list" border style="width: 100%">
                <el-table-column type="index" align="center" label="序号" width="50"></el-table-column>
                <el-table-column prop="modr_no" align="center" label="材料采购号" width="130">
                  <template v-slot="scope">
                    <span v-html="scope.row.modr_no.replaceAll(',', '<br/>')"></span>
                  </template>
                </el-table-column>
                <el-table-column prop="podr_no" align="center" label="成品采购号">
                  <template v-slot="scope">
                    <span v-html="scope.row.podr_no.replaceAll(',', '<br/>')"></span>
                  </template>
                </el-table-column>
                <el-table-column prop="mtrb_name" align="center" label="材料名称" width="130">
                  <template v-slot="scope">
                    <span v-html="scope.row.mtrb_name.replaceAll(',', '<br/>')"></span>
                  </template>
                </el-table-column>
                <el-table-column prop="dent_mtrb_nums" align="center" width="80px" label="订单数量">
                  <template v-slot="scope">
                    <span v-if="scope.row.dent_mtrb_znums" v-html="scope.row.dent_mtrb_znums.replaceAll(',', '<br/>')"></span>
                    <span v-else>{{ scope.row.dent_mtrb_num }}</span>
                  </template>
                </el-table-column>
                <el-table-column prop="dent_mtrb_bnums" align="center" width="80px" label="备品数量">
                  <template v-slot="scope">
                    <span v-if="scope.row.dent_mtrb_bnums" v-html="scope.row.dent_mtrb_bnums.replaceAll(',', '<br/>')"></span>
                    <span v-else>{{ scope.row.dent_mtrb_bnum }}</span>
                  </template>
                </el-table-column>
                <el-table-column prop="dent_mtrb_qpc" align="center" width="80px" label="装箱数量"></el-table-column>
                <el-table-column prop="dent_mtrb_dnum" align="center" width="100px" label="本次送货总数"></el-table-column>
                <el-table-column prop="case_weight" align="center" width="80px" label="每箱重量"></el-table-column>
                <el-table-column prop="dent_mtrb_box" align="center" width="80px" label="总箱数"></el-table-column>
                <el-table-column label="备注" width="165px" align="center">
                  <template v-slot="scope">
                    <el-input
                      v-if="printFlag"
                      type="textarea"
                      :rows="2"
                      placeholder="请输入内容"
                      v-model="scope.row.orderRemarks"
                    ></el-input>
                    <div v-else>{{ scope.row.orderRemarks }}</div>
                  </template>
                </el-table-column>
                <el-table-column min-width="80" align="center">
                  <template #header>
                    <p>物流单号</p>
                    <p>司机联系方式</p>
                  </template>
                  <template v-slot="scope">
                    <p>{{ scope.row.logistics_no }}</p>
                    <p>{{ scope.row.driv_name }}</p>
                    <p>{{ scope.row.driv_phone }}</p>
                  </template>
                </el-table-column>
              </el-table>
              <el-col :span="24">友情提示:</el-col>
              <el-col :span="24">外箱潮湿,破损一律拒签!!!</el-col>
              <el-col :span="24">货物收到请及时清点数量,若有疑问请及时与我司联系.</el-col>
            </el-row>
          </div>
        </div>
        <el-backtop target=".printDialog" :bottom="100" :visibility-height="1" />
        <div slot="title" style="display: flex; justify-content: flex-end">
          <el-button size="small" @click="printReviewVisible1 = false">取消</el-button>
          <el-button type="primary" size="small" @click="printDent1('printDent')">打印</el-button>
        </div>
      </el-dialog>
      <el-dialog
        class="printDialog2"
        title="打印预览"
        :visible.sync="printReviewVisible2"
        @close="printFlag = true"
        width="1200px"
        :show-close="false"
      >
        <div id="printDent2" v-if="printReviewVisible2">
          <div class="vd_print_d">
            <el-row>
              <el-row class="vg_mb_16 vd_print_c">
                <h1>供应商简称-发货明细</h1>
              </el-row>
              <el-row class="vg_mb_16 vd_font18">
                <el-col :md="8"> 发货方: {{ deepDialogForm.supp_abbr }} </el-col>
                <el-col :md="8"> 收货方: {{ deepDialogForm.supp_babbr }} </el-col>
                <el-col :md="8"> 发货日期: {{ helper.toStringDay(deepDialogForm.dent_date / 1000) }} </el-col>
              </el-row>
              <el-table class="vg_mb_16" :data="deepDialogForm.dent_mtrb_list" border style="width: 100%">
                <el-table-column type="index" align="center" label="序号" width="50"> </el-table-column>
                <el-table-column prop="modr_no" align="center" label="材料采购号"> </el-table-column>
                <el-table-column prop="podr_no" align="center" label="成品采购号"> </el-table-column>
                <el-table-column prop="mtrb_name" align="center" label="材料名称" width="130"> </el-table-column>
                <el-table-column prop="dent_mtrb_dnum" align="center" width="80px" label="订单数量"> </el-table-column>
                <el-table-column prop="dent_mtrb_bnum" align="center" width="80px" label="备品数量"> </el-table-column>
                <el-table-column prop="dent_mtrb_qpc" align="center" width="80px" label="装箱数量"> </el-table-column>
                <el-table-column prop="dent_mtrb_dnum" align="center" width="100px" label="本次送货总数">
                  <template v-slot="scope">
                    <span>{{ scope.row.dent_mtrb_bnum + scope.row.dent_mtrb_dnum }}</span>
                  </template>
                </el-table-column>
                <el-table-column prop="case_weight" align="center" width="80px" label="每箱重量"> </el-table-column>
                <el-table-column prop="dent_mtrb_box" align="center" width="80px" label="总箱数"> </el-table-column>
                <el-table-column label="备注" width="200px" align="center">
                  <template v-slot="scope">
                    <el-input v-if="printFlag" type="textarea" :rows="2" placeholder="请输入内容" v-model="scope.row.orderRemarks">
                    </el-input>
                    <div v-else>{{ scope.row.orderRemarks }}</div>
                  </template>
                </el-table-column>
                <el-table-column min-width="80" align="center">
                  <template #header>
                    <p>物流单号</p>
                    <p>司机联系方式</p>
                  </template>
                  <template v-slot="scope">
                    <p>{{ scope.row.logistics_no }}</p>
                    <p>{{ scope.row.driv_name }}</p>
                    <p>{{ scope.row.driv_phone }}</p>
                  </template>
                </el-table-column>
              </el-table>
              <el-col :span="24">友情提示:</el-col>
              <el-col :span="24">外箱潮湿,破损一律拒签!!!</el-col>
              <el-col :span="24">货物收到请及时清点数量,若有疑问请及时与我司联系.</el-col>
            </el-row>
          </div>
        </div>
        <el-backtop target=".printDialog2" :bottom="100" :visibility-height="1" />
        <div slot="title" style="display: flex; justify-content: flex-end">
          <el-button size="small" @click="printReviewVisible2 = false">取消</el-button>
          <el-button type="primary" size="small" @click="printDent1('printDent2')">打印</el-button>
        </div>
      </el-dialog>
      <el-dialog title="批量填充" width="30%" :visible.sync="batchFillFlag">
        <el-row class="vg_mb_8 vd_dis_cen">
          <el-col :md="6" class="vd_tr">每箱重量:</el-col>
          <el-col :md="8">
            <el-input
              v-model="eachWeight"
              size="small"
              placeholder="请输入每箱重量"
              @input="eachWeight = helper.keepTNum1(eachWeight)"
              @blur="eachWeight = helper.calcPrice(eachWeight, 4, 100000000)"
              maxlength="13"
              show-word-limit
            ></el-input>
          </el-col>
        </el-row>
        <el-row class="vg_mb_8 vd_dis_cen">
          <el-col :md="6" class="vd_tr">物流单号:</el-col>
          <el-col :md="8">
            <el-input v-model="logisticsBillNo" size="small" placeholder="请输入物流单号"></el-input>
          </el-col>
        </el-row>
        <el-row class="vg_mb_8 vd_dis_cen">
          <el-col :md="6" class="vd_tr">司机姓名:</el-col>
          <el-col :md="8">
            <el-input v-model="drivName" size="small" placeholder="请输入司机姓名"></el-input>
          </el-col>
        </el-row>
        <el-row class="vg_mb_8 vd_dis_cen">
          <el-col :md="6" class="vd_tr">联系方式:</el-col>
          <el-col :md="8">
            <el-input v-model="drivPhone" size="small" placeholder="请输入联系方式"></el-input>
          </el-col>
        </el-row>
        <el-row class="vd_dis_r">
          <el-button @click="batchFillConfirm" type="primary" size="small">确认</el-button>
        </el-row>
      </el-dialog>
      <el-dialog title="发货明细记录" :visible.sync="dentDialogVisible" width="35%">
        <div v-if="dentList.length > 0" style="width: 100%; display: flex">
          <div style="width: 35%">
            <div v-for="(item, i) in dentList" :key="i" @click="loadDentDetailList(item)" class="vg_mb_16 vg_cursor">
              <el-link type="danger" :underline="false" class="vg_cursor" style="font-size: 18px; color: #b21d13; font-weight: 700">{{
                item.modr_no
              }}</el-link>
            </div>
          </div>
          <div style="width: 65%">
            <div v-for="(item, index) in dentDetailList" :key="index" class="vg_mb_16">
              <el-link type="primary" style="font-size: 18px" @click="showPrintDetailDialog(item)" class="vg_cursor">
                <span>{{ item[0] }}. </span
                ><span>{{ item[1].create_date }} 发货总计 {{ item[1].dent_mtrb_dnum }} 共 {{ item[1].dent_mtrb_box }} 箱 </span>
              </el-link>
            </div>
          </div>
        </div>
        <div v-else>
          <div v-for="(item, index) in dentDetailList" :key="index" class="vg_mb_16">
            <el-link type="primary" style="font-size: 18px" @click="showPrintDetailDialog(item)" class="vg_cursor">
              <span>{{ item[0] }}. </span
              ><span>{{ item[1].create_date }} 发货总计 {{ item[1].dent_mtrb_dnum }} 共 {{ item[1].dent_mtrb_box }} 箱 </span>
            </el-link>
          </div>
        </div>
      </el-dialog>
      <!--      <el-row class="vg_mt_16 vd_bortop">
        <el-col>
          <inputUser :isCookie="false" :stffForm="stffForm" ref="userData" v-if="dentForm.stff_name" />
        </el-col>
      </el-row>-->
    </el-form>
  </div>
</template>

<script>
import { get, post } from '@api/request';
import { dentAPI } from '@api/modules/dent';
import { fcreAPI } from '@api/modules/fcre';
import editHeader from '@/views/component/editHeader';
import inputUser from '@/views/component/inputUser';
import DentSubtableList from './Component/DentSubtableList';
import { cloneDeep } from 'lodash';
import printJS from 'print-js';
import domtoimage from 'dom-to-image';
import { suppAPI } from '@api/modules/supp';

export default {
  name: 'dentEditMain',
  components: {
    editHeader,
    inputUser,
    DentSubtableList
  },
  data() {
    return {
      suppList: [],
      rules: {},
      dentForm: {},
      isShow: true,
      btn: {},
      stffForm: {},
      loadingFlag: false,
      multiSelection: [],
      splitFlag: false,
      splitNum: 1,
      splitCopyList: [],
      splitMaxNum: 0,
      splitedList: [],
      mergedList: [],
      minusIndex: -1,
      mapList: new Map(),
      printReviewVisible: false,
      printReviewVisible1: false,
      printReviewData: [],
      dialogForm: [],
      ids: 1,
      userInfo: {},
      printFlag: true,
      dataUrl: '',
      batchFillFlag: false,
      eachWeight: '',
      logisticsBillNo: '',
      showFlag: false,
      dentDialogVisible: false,
      dentDetailList: {},
      printReviewVisible2: false,
      deepDialogForm: {},
      dentList: [],
      drivPhone: '',
      drivName: ''
    };
  },
  created() {
    let ids = this.$route.query.ids;
    if (ids) {
      this.showFlag = true;
    }
    this.userInfo = this.$cookies.get('userInfo');
    this.initData();
  },
  watch: {
    multiSelection(newValue, oldValue) {
      if (newValue.length > 0) this.splitMaxNum = newValue[0].dent_mtrb_num;
    }
  },
  methods: {
    initData() {
      this.loadingFlag = false;
      this.getDentInfo();
      this.getSupp();
    },
    showPrintDetailDialog(val) {
      let id = this.$route?.query?.form_id;
      if (!id) {
        id = val[1]?.dent_id;
      }
      get(dentAPI.getDentDetailByNumAndDentId, { dent_id: id, dent_deta_num: val[0] }).then(({ data }) => {
        if (data.code === 0) {
          let deepDentFrom = cloneDeep(this.dentForm);
          let dentMtrbList = deepDentFrom?.dent_mtrb_list;
          let temp = [];
          if (dentMtrbList?.length > 0) {
            data.data?.dentDetaList.forEach(item => {
              let dentMtrb = cloneDeep(dentMtrbList[0]);
              dentMtrb.case_weight = item.case_weight;
              dentMtrb.dent_deta_num = item.dent_deta_num;
              dentMtrb.dent_mtrb_bnum = item.dent_mtrb_bnum;
              dentMtrb.dent_mtrb_box = item.dent_mtrb_box;
              dentMtrb.dent_mtrb_dnum = item.dent_mtrb_dnum;
              dentMtrb.dent_mtrb_num = item.dent_mtrb_num;
              dentMtrb.dent_mtrb_id = item.dent_mtrb_id;
              dentMtrb.dent_mtrb_ynum = item.dent_mtrb_ynum;
              dentMtrb.driv_name = item.driv_name;
              dentMtrb.driv_phone = item.driv_phone;
              dentMtrb.logistics_no = item.logistics_no;
              dentMtrb.mtrb_name = item.mtrb_name;
              dentMtrb.dent_mtrb_qpc = item.dent_mtrb_dnum / item.dent_mtrb_box;
              temp.push(dentMtrb);
            });
            deepDentFrom.dent_mtrb_list = temp;
            this.deepDialogForm = deepDentFrom;
            this.printReviewVisible2 = true;
          }
        }
      });
    },
    loadDentDetailList(item) {
      this.loadDentData(item.dent_id);
    },
    showDentDetailDialog() {
      let formId = this.$route?.query?.form_id;
      let ids = this.$route?.query?.ids;
      if (ids) {
        get(dentAPI.getDentNosByDentIds, { dentIds: ids }).then(({ data }) => {
          if (data.code === 0) {
            this.dentList = data.data.list;
            this.dentDialogVisible = true;
          }
        });
      } else {
        this.loadDentData(formId);
      }
    },
    loadDentData(formId) {
      get(dentAPI.getDentDetailByDentId, { dent_id: formId }).then(({ data }) => {
        if (data.code === 0) {
          this.dentDialogVisible = true;
          this.dentDetailList = Object.entries(data.data);
        } else {
          this.$message.warning('获取信息失败!');
        }
      });
    },
    //启用编辑
    openEdit() {
      this.isShow = false;
      this.$emit('isShow', this.isShow);
    },
    //撤销编辑
    closeEdit() {
      this.$confirm('是否撤销编辑?', '提示', {
        confirmButtonText: '是',
        cancelButtonText: '否',
        type: 'warning'
      })
        .then(() => {
          this.isShow = true;
          this.$emit('isShow', this.isShow);
          this.initData();
          this.$message({
            type: 'info',
            message: '已撤销编辑!'
          });
        })
        .catch(() => {});
    },
    //提交表单
    submit(formName) {
      // let list = [];
      // this.dentForm.dent_mtrb_list.forEach((item,index)=>{
      // 	if(!item.imge_id){
      // 		list.push(index+1)
      // 	}
      // })
      // if(list.length>0) return this.$message.warning(`第${list.join(',')}条数据未上传图片`)
      this.$confirm('确认提交?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.$refs[formName].validate(valid => {
            if (valid) {
              this.saveInfo();
            } else {
              this.$message.warning('请把必填内容补充完整');
              return false;
            }
          });
        })
        .catch(() => {});
    },
    // 保存
    saveInfo() {
      let dentForm = JSON.parse(JSON.stringify(this.dentForm));
      dentForm.dent_date = parseInt(Number(new Date(dentForm.dent_date).getTime()) / 1000);
      dentForm.deli_date = parseInt(Number(new Date(dentForm.deli_date).getTime()) / 1000);
      let temp = [];
      let temp1 = [];
      dentForm.dent_mtrb_list.forEach((item, index) => {
        /*				if (item.mtrb_name.indexOf(",") !== -1) {
					temp1 = item.split(",")
					item.mtrb_name = [...(new Set(temp1))].join(',')
					temp1 = [];
				}*/
        if (Number(item.dent_mtrb_dnum) % Number(item.dent_mtrb_qpc) !== 0) temp1.push(index + 1);
      });
      if (temp.length > 0) return this.$message.warning(`第${temp.join(',')}条数据的装箱数量有误，填写保存`);
      dentForm.dent_mtrb_list = [...this.splitedList, ...this.mergedList, ...dentForm.dent_mtrb_list];
      this.splitedList = [];
      this.mergedList = [];
      let ids = this.$route.query.ids;
      if (ids) {
        post(dentAPI.editDents, dentForm).then(res => {
          if (res.data.code === 0) {
            this.$message({
              type: 'success',
              message: '保存成功'
            });
            this.isShow = true;
            this.$emit('isShow', this.isShow);
            this.splitCopyList = [];
            this.initData();
            this.$store.commit('setDentSubTable', []);
          }
        });
      } else {
        post(dentAPI.editDent, dentForm).then(res => {
          if (res.data.code === 0) {
            this.$message({
              type: 'success',
              message: '保存成功'
            });
            this.isShow = true;
            this.$emit('isShow', this.isShow);
            this.splitCopyList = [];
            this.initData();
          }
        });
      }
    },
    // 恢复上一步
    recoveryUpper() {
      let ids = this.$route.query.ids;
      // if (ids) return this.$message.warning('合并发货的数据不能恢复')
      this.$confirm('确定恢复?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          if (ids) {
            get(dentAPI.cancalDentInfoByIds, { dent_ids: ids, data_status: 1 }).then(res => {
              if (res.data.code === 0) {
                this.$message.success('恢复成功!');
                Object.assign(this.$data, this.$options.data());
                this.initData();
              } else {
                let mg = res.data.msg;
                let tp = 'error';
                this.$message({ message: mg, type: tp });
              }
            });
          } else {
            get(dentAPI.cancalDentInfo, { dent_id: this.dentForm.dent_id, data_status: 1 }).then(res => {
              if (res.data.code === 0) {
                this.$message.success('恢复成功!');
                Object.assign(this.$data, this.$options.data());
                this.initData();
              } else {
                let mg = res.data.msg;
                let tp = 'error';
                this.$message({ message: mg, type: tp });
              }
            });
          }
        })
        .catch(() => {});
    },
    // 恢复原数据
    recoveryPrimary() {
      let ids = this.$route.query.ids;
      // if (ids) return this.$message.warning('合并发货的数据不能恢复')
      this.$confirm('确定恢复?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          if (ids) {
            get(dentAPI.cancalDentInfoByIds, { dent_ids: ids, data_status: 0 }).then(res => {
              if (res.data.code === 0) {
                this.$message.success('恢复成功!');
                Object.assign(this.$data, this.$options.data());
                this.initData();
              } else {
                let mg = res.data.msg;
                let tp = 'error';
                this.$message({ message: mg, type: tp });
              }
            });
          } else {
            get(dentAPI.cancalDentInfo, { dent_id: this.dentForm.dent_id, data_status: 0 }).then(res => {
              if (res.data.code === 0) {
                this.$message.success('恢复成功!');
                Object.assign(this.$data, this.$options.data());
                this.initData();
              } else {
                let mg = res.data.msg;
                let tp = 'error';
                this.$message({ message: mg, type: tp });
              }
            });
          }
        })
        .catch(() => {});
    },
    // 刷新按钮
    buttonRefresh() {
      this.initData();
    },
    // 发送加工厂
    sendOutFactory() {
      if (this.multiSelection.length === 0) {
        this.$message.warning('请至少选择一条数据!');
        return;
      }
      let dnumArr = this.multiSelection.map(({ dent_mtrb_dnum }) => Object.values({ dent_mtrb_dnum }).toString());
      let find = dnumArr.filter(x => x === '0');
      if (find.length > 0) return this.$message.warning('本次送货数量不能为0!');

      let flag = false;
      this.multiSelection.forEach(item => {
        if (Number(item.dent_mtrb_qpc) === 0) flag = true;
        this.dentForm.dent_mtrb_list[item.index].sendFlag = true;
        this.dentForm.dent_mtrb_list[item.index].changeFlag = false;
        this.dentForm.dent_mtrb_list[item.index].mergeFlag = false;
        this.dentForm.dent_mtrb_list[item.index].isSplit = false;
      });
      if (flag) return this.$message.warning('箱规不可为0');
      let params = {};
      params.dent_id = this.dentForm.dent_id;
      params.modr_id = this.dentForm.modr_id;
      params.modr_no = this.dentForm.modr_no;
      params.podr_id = this.dentForm.podr_id;
      params.podr_no = this.dentForm.podr_no;
      params.cust_id = this.dentForm.cust_id;
      params.cust_abbr = this.dentForm.cust_abbr;
      params.puca_stff_id = this.dentForm.puca_stff_id;
      params.puca_stff_name = this.dentForm.puca_stff_name;
      params.dent_mtrb_list = this.multiSelection;
      params.dent_date = parseInt(Number(new Date(this.dentForm.dent_date).getTime()) / 1000);
      post(fcreAPI.addFcre, params).then(res => {
        if (res.data.code === 0) {
          this.$message({
            type: 'success',
            message: '发送成功'
          });
          this.buttonRefresh();
          //this.jump('/fcre_list', {perm_id: 146})
        }
      });
    },
    // 二维码编号复制
    copySty() {
      if (this.dentForm.dent_addr.length > 0) {
        let str = this.dentForm.dent_addr;
        navigator.clipboard.writeText(str).then(
          () => {
            this.$message.success('复制成功');
          },
          () => {
            this.$message.warning('复制失败');
          }
        );
      } else {
        this.$message.warning('无内容');
      }
    },
    // 子表勾选
    handleSelectionChange(val) {
      this.multiSelection = val;
    },
    // 拆分
    splitClick() {
      if (this.multiSelection.length !== 1) return this.$message.warning('请选择一条数据!');
      // if (this.multiSelection[0].dent_mtrb_num === 0) return this.$message.warning("拆分的订单数量不能为0!")
      let mergeFlag = false;
      let orderNum = 0;
      let dentMtrbType = 1;
      this.multiSelection.forEach(item => {
        if (item.isMerge || item.mergeFlag === true) mergeFlag = true;
        if (dentMtrbType !== 2 || dentMtrbType !== 3) {
          if (item.dent_mtrb_type === 3) dentMtrbType = 3;
          if (item.dent_mtrb_type === 2) dentMtrbType = 2;
        }
        if (orderNum === 0) {
          orderNum = Number(item.dent_mtrb_num);
        }
      });
      if (orderNum === 0) return this.$message.warning('拆分的订单数量不能为0!');
      if (mergeFlag) return this.$message.warning('多种材料合并后不可再拆分!');
      if (dentMtrbType === 3) return this.$message.warning('补货单不能拆分!');
      if (dentMtrbType === 2) return this.$message.warning('备品单不能拆分!');
      this.splitFlag = true;
    },
    // 拆分确定
    confirmSplit() {
      let orderFlag = false;
      this.multiSelection.forEach(item => {
        if (!orderFlag) {
          if (this.splitNum >= Number(item.dent_mtrb_num)) orderFlag = true;
        }
      });
      if (orderFlag) return this.$message.warning('拆分数量不可大于订单数量!');
      let multiSelectionCopy = JSON.parse(JSON.stringify(this.multiSelection));
      let sortList = [];
      this.$store.commit('setDentSubTableBackup', cloneDeep(this.dentForm.dent_mtrb_list));
      // let [{dent_id, dent_mtrb_num, dent_mtrb_type, isMerge}] = this.multiSelection
      // 判断拆分数量相等 不做处理              // 备品不能进行操作        // 补品不能进行操作
      // if(this.splitNum === dent_mtrb_num || dent_mtrb_type === 2 || dent_mtrb_type === 3) {
      // 	this.$refs.DentSubtableList.clearSelection();
      // 	this.splitFlag = false;
      // 	if (dent_mtrb_type === 3) {
      // 		this.$message.warning("补货单不能拆分!")
      // 	}
      // 	if (dent_mtrb_type === 2) {
      // 		this.$message.warning("备品单不能拆分!")
      // 	}
      // 	return;
      // }
      // 删除的对象增加标识 存入
      multiSelectionCopy.forEach(item => {
        item.dent_mtrb_status = 1;
        this.splitedList.push(item);
        this.dentForm.dent_mtrb_list.forEach((ite, ind) => {
          if (item.dent_mtrb_id === ite.dent_mtrb_id && item.dent_mtrb_num === ite.dent_mtrb_num) {
            sortList.push(ind);
          }
        });
      });
      // let findIndex = this.dentForm.dent_mtrb_list.findIndex(x => x.dent_id === dent_id && x.dent_mtrb_num === dent_mtrb_num);
      // this.dentForm.dent_mtrb_list[findIndex].dent_mtrb_status = 1
      // this.splitedList.push(this.dentForm.dent_mtrb_list[findIndex])
      // 删除原数组
      sortList = sortList.sort((a, b) => {
        return b - a;
      });
      for (let i of sortList) {
        this.dentForm.dent_mtrb_list.splice(i, 1);
      }
      this.multiSelection.forEach((item, index) => {
        // 已拆分数量
        let split = cloneDeep(item);
        split.dent_mtrb_num = split.dent_mtrb_num - this.splitNum;
        split.dent_mtrb_dnum = split.dent_mtrb_num + split.dent_mtrb_bnum;
        split.dent_mtrb_status = 2;
        split.dent_mtrb_id = this.minusIndex = this.minusIndex - 1;
        split.dent_mtrb_box = null;
        split.dent_mtrb_qpc = null;
        // split.dent_mtrb_box = this.NumberOfBoxesChange(split.dent_mtrb_dnum,split.dent_mtrb_qpc)
        // 增加是否拆分标识
        split.isSplit = true;
        split.splitFlag = true;
        // 判断类型不是普通单
        if (split.dent_mtrb_type !== 0) {
          split.dent_mtrb_num === 0 ? (split.dent_mtrb_type = 2) : (split.dent_mtrb_type = 1);
        }
        // 剩余数量
        let residue = cloneDeep(item);
        residue.dent_mtrb_num = residue.dent_mtrb_num - split.dent_mtrb_num;
        residue.dent_mtrb_dnum = residue.dent_mtrb_num;
        residue.dent_mtrb_status = 2;
        residue.dent_mtrb_ynum = 0;
        residue.dent_mtrb_bnum = 0;
        residue.dent_mtrb_box = null;
        residue.dent_mtrb_qpc = null;
        // residue.dent_mtrb_box = this.NumberOfBoxesChange(residue.dent_mtrb_dnum, residue.dent_mtrb_qpc);
        residue.dent_mtrb_id = this.minusIndex = this.minusIndex - 1;
        // 增加是否拆分标识
        residue.isSplit = true;
        residue.splitFlag = true;
        if (residue.dent_mtrb_type === 1) {
          residue.dent_mtrb_type = 0;
        }
        this.dentForm.dent_mtrb_list.unshift(residue);
        this.dentForm.dent_mtrb_list.unshift(split);
      });
      this.splitNum = 1;
      this.splitFlag = false;
      this.$refs.DentSubtableList.$refs.multiTable.$refs.multiTable.clearSelection();
      // 重置key与map数据
      this.mapList.clear();
      this.dentForm.dent_mtrb_list.forEach((item, index) => {
        item.key = index;
        this.mapList.set(index, item);
      });
    },
    /*		// 计算箱数
		NumberOfBoxesChange(dnum,qpc){
			if (Number(dnum) % Number(qpc) !== 0) {
				this.$message.warning('箱数不能取小数，已向上取整');
				return  Math.ceil(Number(dnum) / Number(qpc));
			} else {
				return  Number(dnum) / Number(qpc);
			}
		},*/
    // 合并
    mergeClick() {
      if (this.multiSelection.length < 2) return this.$message.warning('请至少选择两条数据!');
      // let ids = this.multiSelection.map(({dent_id}) => (Object.values({dent_id}).toString()));
      let flag = false;
      this.multiSelection.filter(item => {
        if (this.multiSelection[0].dent_mtrb_type === 3) {
          if (item.dent_mtrb_type !== this.multiSelection[0].dent_mtrb_type) flag = true;
        }
      });
      if (flag) return this.$message.warning('补货单不可合并!');
      /*			if (new Set(this.multiSelection.map(({dent_mtrb_type}) => (Object.values({dent_mtrb_type}).toString()))).size > 1) {
				this.$message.warning('只有相同的订单类型才能合并!')
				this.$refs.DentSubtableList.clearSelection();
				return
			}*/
      // if (this.multiSelection.filter(x => x.isSplit === true).length !== this.multiSelection.length) {
      // 	this.$refs.DentSubtableList.clearSelection();
      // 	this.$message.warning('未拆分的数据不可合并!')
      // 	return
      // }
      this.$store.commit('setDentSubTableBackup', cloneDeep(this.dentForm.dent_mtrb_list));

      let seletionArr = cloneDeep(this.multiSelection);
      // 选择需要操作的字段
      let mapArr = seletionArr.map(
        ({
          dent_mtrb_id,
          mtrb_ids,
          mtrb_list,
          mtrb_name,
          mtrb_spec,
          dent_mtrb_num,
          dent_mtrb_bnum,
          dent_mtrb_ynum,
          dent_id,
          parent_id,
          modr_no,
          podr_no
        }) => ({
          dent_mtrb_id,
          mtrb_ids,
          mtrb_list,
          mtrb_name,
          mtrb_spec,
          dent_mtrb_num,
          dent_mtrb_bnum,
          dent_mtrb_ynum,
          dent_id,
          parent_id,
          modr_no,
          podr_no
        })
      );
      let mtrbParentIds = [];
      let mtrbIds = new Set();
      let mtrbName = new Set();
      let mtrbSpec = new Set();
      let modrNo = new Set();
      let podrNo = new Set();
      let dentMtrbNum = 0;
      let dentMtrbBnum = 0;
      let dentMtrbYnum = 0;
      let dentId = seletionArr[0].dent_id;
      let dentIds = [];
      seletionArr.forEach((item, index) => {
        if (index !== 0) {
          if (dentIds.indexOf(item.dent_id) === -1) {
            if (item.dent_id !== dentId) {
              dentIds.push(item.dent_id);
            }
          }
        }
      });
      // 合并数据
      mapArr.forEach(
        ({ dent_mtrb_id, mtrb_ids, mtrb_name, mtrb_spec, dent_mtrb_num, dent_mtrb_bnum, dent_mtrb_ynum, parent_id, modr_no, podr_no }) => {
          if (parent_id.indexOf(',') !== -1) {
            mtrbParentIds = parent_id.split(',').concat(mtrbParentIds);
          } else {
            mtrbParentIds.push(dent_mtrb_id);
          }
          mtrbIds.add(mtrb_ids);
          mtrbName.add(mtrb_name);
          mtrbSpec.add(mtrb_spec);
          modrNo.add(modr_no);
          podrNo.add(podr_no);
          dentMtrbNum += dent_mtrb_num;
          dentMtrbBnum += dent_mtrb_bnum;
          dentMtrbYnum += dent_mtrb_ynum;
        }
      );
      // 整合数据
      let cloneMtrbList = cloneDeep(seletionArr[0]);
      cloneMtrbList.isMerge = true;
      cloneMtrbList.dent_id = dentId;
      if (dentIds.length > 0) {
        cloneMtrbList.dent_ids = dentIds.join(',');
      } else {
        cloneMtrbList.dent_ids = '';
      }
      cloneMtrbList.dent_mtrb_id = this.minusIndex = this.minusIndex - 1;
      cloneMtrbList.parent_id = mtrbParentIds.join(',');
      cloneMtrbList.mtrb_ids = [...mtrbIds].join(',');
      cloneMtrbList.mtrb_name = [...mtrbName].join(',');
      cloneMtrbList.mtrb_spec = [...mtrbSpec].join(',');
      cloneMtrbList.modr_no = [...modrNo].join(',');
      cloneMtrbList.podr_no = [...podrNo].join(',');
      cloneMtrbList.dent_mtrb_num = dentMtrbNum;
      cloneMtrbList.dent_mtrb_dnum = dentMtrbNum - dentMtrbYnum + dentMtrbBnum;
      cloneMtrbList.dent_mtrb_box = 1;
      cloneMtrbList.dent_mtrb_qpc = dentMtrbNum - dentMtrbYnum + dentMtrbBnum;
      cloneMtrbList.dent_mtrb_bnum = dentMtrbBnum;
      cloneMtrbList.dent_mtrb_ynum = dentMtrbYnum;
      cloneMtrbList.dent_mtrb_type = dentMtrbNum === 0 && dentMtrbBnum !== 0 ? 2 : dentMtrbNum !== 0 && dentMtrbBnum === 0 ? 0 : 1;
      cloneMtrbList.dent_mtrb_status = 3;
      cloneMtrbList.mergeFlag = true;
      // 合并之前的数组增加标识 存入状态
      cloneDeep(this.multiSelection).forEach(item => {
        this.mapList.delete(item.key);
        item.dent_mtrb_status = 4;
        this.mergedList.push(item);
      });
      // 覆盖原数组
      this.dentForm.dent_mtrb_list = Array.from(this.mapList.values());
      // 添加整合数据
      this.dentForm.dent_mtrb_list.unshift(cloneMtrbList);
      // 重置key与mapData
      this.mapList.clear();
      this.dentForm.dent_mtrb_list.forEach((item, index) => {
        item.key = index;
        this.mapList.set(index, item);
      });
    },
    //批量填充打开弹框
    batchFill() {
      this.batchFillFlag = true;
    },
    // 批量填充确认
    batchFillConfirm() {
      this.$confirm('确定批量填充?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.dentForm.dent_mtrb_list.forEach((item, index) => {
            if (this.eachWeight) {
              item.case_weight = this.eachWeight;
            }
            if (this.logisticsBillNo) {
              item.logistics_no = this.logisticsBillNo;
            }
            if (this.drivName) {
              item.driv_name = this.drivName;
            }
            if (this.drivPhone) {
              item.driv_phone = this.drivPhone;
            }
          });
          this.batchFillFlag = false;
        })
        .catch(() => {
          this.batchFillFlag = false;
        });
    },
    //表单重置
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    // 获取表单信息
    getDentInfo() {
      this.dentForm.dent_id = this.$route.query.form_id;
      let ids = this.$route.query.ids;
      if (ids) {
        get(dentAPI.getDentByIds, { dent_ids: ids }).then(res => {
          if (res.data.code === 0) {
            this.dentForm = res.data.data.form;
            this.dentForm.dent_mtrb_list.forEach((item, index) => {
              this.mapList.set(index, item);
              if (item.dent_mtrb_num === item.dent_mtrb_ynum) {
                item.disabled = true;
              }
            });
            this.btn = res.data.data.btn;
            if (this.userInfo.acct_id === 1) {
              this.btn.edit = false;
            }
            this.stffForm.stff_name = this.dentForm.stff_name;
            this.stffForm.dept_name = this.dentForm.dept_name;
            this.stffForm.dept_team_name = this.dentForm.dept_team_name;
            this.stffForm.user_id = this.dentForm.user_id;
            this.stffForm.dept_id = this.dentForm.dept_id;
            this.stffForm.stff_id = this.dentForm.stff_id;
            this.dentForm.dent_date = this.dentForm.dent_date ? this.dentForm.dent_date * 1000 : null;
            this.dentForm.deli_date = this.dentForm.deli_date ? this.dentForm.deli_date * 1000 : null;
            this.calc();
            this.$store.commit('setDentSubTableBackup', cloneDeep(this.dentForm.dent_mtrb_list));
            setTimeout(() => {
              this.loadingFlag = false;
            }, 500);
          } else {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
          }
        });
      } else {
        get(dentAPI.getDentById, { dent_id: this.dentForm.dent_id }).then(res => {
          if (res.data.code === 0) {
            this.dentForm = res.data.data.form;
            this.dentForm.dent_mtrb_list.forEach((item, index) => {
              this.mapList.set(index, item);
              if (item.dent_mtrb_num === item.dent_mtrb_ynum) {
                item.disabled = true;
              }
            });
            this.btn = res.data.data.btn;
            if (this.userInfo.acct_id === 1) {
              this.btn.edit = false;
            }
            this.stffForm.stff_name = this.dentForm.stff_name;
            this.stffForm.dept_name = this.dentForm.dept_name;
            this.stffForm.dept_team_name = this.dentForm.dept_team_name;
            this.stffForm.user_id = this.dentForm.user_id;
            this.stffForm.dept_id = this.dentForm.dept_id;
            this.stffForm.stff_id = this.dentForm.stff_id;
            this.dentForm.dent_date = this.dentForm.dent_date ? this.dentForm.dent_date * 1000 : null;
            this.dentForm.deli_date = this.dentForm.deli_date ? this.dentForm.deli_date * 1000 : null;
            this.calc();
            this.$store.commit('setDentSubTableBackup', cloneDeep(this.dentForm.dent_mtrb_list));
            setTimeout(() => {
              this.loadingFlag = false;
            }, 500);
          } else {
            this.$message.error(res.data.msg);
          }
        });
      }
    },
    calc() {
      let query = this.$route.query;
      this.dentForm.dent_mtrb_list.forEach((item, index) => {
        let temp = [];
        let temp1 = [];
        this.$set(this.dentForm.dent_mtrb_list[index], 'orderRemarks', '');
        if (!item.podr_no) {
          item.podr_no = this.dentForm.podr_no;
        }
        if (!item.modr_no) {
          item.modr_no = this.dentForm.modr_no;
        }
        item.case_weight = this.helper.haveFour(item.case_weight);
        // item.orderRemarks = ''
        item.mtrb_list.forEach(ite => {
          temp.push(ite.mtrb_name);
          temp1.push(ite.mtrb_spec);
        });
        // item.mtrb_name = temp.join(',')
        // item.mtrb_spec = temp1.join(',')
        if (query.autoFlag === '1') {
          item.driv_name = query.name;
          item.driv_phone = query.telPhone;
        }
        item.key = Number(index);
      });
    },
    takeRightWhile(arr, fn) {
      for (const [i, val] of [...arr].reverse().entries()) if (!fn(val)) return i === 0 ? [] : arr.slice(-i);
      return arr;
    },
    showPrintDialog() {
      let ids = this.$route.query.ids;
      if (ids) {
        this.dialogForm = [];
        ids.split(',').forEach(id => {
          let data = cloneDeep(this.dentForm);
          data.dent_mtrb_list = data.dent_mtrb_list.filter(x => Number(x.dent_id) === Number(id));
          this.dialogForm.push(data);
        });
      } else {
        this.dialogForm[0] = this.dentForm;
      }
      setTimeout(() => {
        this.printReviewVisible = true;
      }, 500);
    },
    showPrintDialog1() {
      if (this.multiSelection.length === 0) {
        return this.$message.warning('请至少选择一条数据!');
      }
      let ids = this.$route.query.ids;
      if (ids) {
        this.dialogForm = [];
        ids.split(',').forEach(id => {
          let data = cloneDeep(this.dentForm);
          data.dent_mtrb_list = this.multiSelection.filter(x => Number(x.dent_id) === Number(id));
          if (data.dent_mtrb_list.length > 0) {
            this.dialogForm.push(data);
          }
        });
      } else {
        let data = cloneDeep(this.dentForm);
        data.dent_mtrb_list = this.multiSelection;
        this.dialogForm[0] = data;
      }
      setTimeout(() => {
        this.printReviewVisible1 = true;
      }, 500);
    },
    printDent(id) {
      this.printFlag = false;
      setTimeout(() => {
        printJS({
          printable: `${id}`,
          type: 'html',
          header: '',
          targetStyles: ['*'],
          style: this.workOrderStyle(),
          onLoadingEnd: () => (this.printReviewVisible = false)
        });
      }, 500);
    },
    printDent1(id) {
      this.printFlag = false;
      let node = document.getElementById(`${id}`);
      domtoimage
        .toJpeg(node, { bgcolor: '#ffffff', quality: '100%' })
        .then(dataUrl => {
          printJS({
            printable: dataUrl,
            type: 'image',
            onLoadingEnd: () => {
              id === 'printDent' ? (this.printReviewVisible1 = false) : (this.printReviewVisible2 = false);
            }
          });
        })
        .catch(err => console.error(err));
    },
    workOrderStyle() {
      return `
			.vd_print_d{
				page-break-after:always
			}
			.vg_mb_16{
				margin-bottom: 16px;
			}
			.vd_font18{
				font-size: 18px;
			}
			.vd_print_c{
				text-align: center;
			}
			.vd_dis_c{
				display: flex;
			}`;
    },
    revoke() {
      this.dentForm.dent_mtrb_list = this.$store.state.dentSubTableBackup;
      this.mapList.clear();
      this.dentForm.dent_mtrb_list.forEach((item, index) => {
        this.mapList.set(index, item);
        if (item.dent_mtrb_num === item.dent_mtrb_ynum) {
          item.disabled = true;
        }
      });
    },
    //获取供应商信息
    getSupp() {
      get(suppAPI.getSuppAll)
        .then(res => {
          if (res.data.code === 0) {
            this.suppList = res.data.data.list;
            return;
          }
          this.$message.error(res.data.msg);
        })
        .catch(res => {
          this.$message.error(res.data.msg);
        });
    }
  }
};
</script>

<style scoped lang="scss">
.vd_bortop {
  border-top: #dcdfe6 solid 1px;
  padding-top: 20px;
}

.vd_edhea {
  position: relative;
}

.vd_export {
  position: absolute;
  top: 0;
  left: 65px;
}
.vd_export1 {
  position: absolute;
  top: 0;
  left: 130px;
}
.vd_bortop {
  border-top: #dcdfe6 solid 1px;
  padding-top: 20px;
}
.vd_dash {
  width: 100%;
  height: 1px;
  border-top: 1px dashed #e9e9e9;
  margin: 10px 0;
}
.vd_word {
  word-wrap: break-word;
  word-break: normal;
}
.vd_export2 {
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  right: 150px;
}
.vd_center {
  display: flex;
  justify-content: center;
}

.vd_print_d {
  page-break-after: always;
}
.vg_mb_16 {
  margin-bottom: 16px;
}
.vd_font18 {
  font-size: 18px;
}
.vd_print_c {
  text-align: center;
}
.vd_dis_c {
  display: flex;
}
#printDent1 {
  padding: 10px;
  box-sizing: border-box;
}
.vd_tr {
  text-align: right;
  padding-right: 10px;
}
.vd_dis_cen {
  display: flex;
  align-items: center;
}
.vd_dis_r {
  display: flex;
  justify-content: right;
}
</style>
